import React, { useState } from "react";
import "../sass/website.global.sass";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import Home from "../components/Home";

// markup
const IndexPage = () => {
  const [loading, updateLoading] = useState(true);
  return (
    <Layout title={`Home`}>
      {/*<Home />*/}
      {/*{loading && <Loading updateLoading={updateLoading} />}*/}
      {!loading ? <Home /> : <Loading updateLoading={updateLoading} />}
    </Layout>
  );
};

export default IndexPage;
