import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as S from "./SVG.module.sass";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategory,
  selectSpot,
  T__Category,
  updateSpot,
} from "../../../../redux/dataSlice";
import classNames from "classnames";

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
      easing: "linear",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      easing: "linear",
    },
  },
};

const Spots = () => {
  const dispatch = useDispatch();
  const category: T__Category = useSelector(selectCategory);
  const spot = useSelector(selectSpot);

  const spotClasses = (c: T__Category, num: number) => {
    // console.log(num, spot);
    const classes = [
      S.Spot,
      { [`${S.Active}`]: c === category, [`${S.Current}`]: spot === num },
    ];
    return classNames(classes);
  };

  const click = (spot: number) => {
    dispatch(updateSpot({ spot }));
  };
  return (
    <g>
      <AnimatePresence>
        {/* GROCERY STORES */}
        {category === "Grocery Stores" && (
          <motion.g
            id={`grocery-stores`}
            key="spots-grocery"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g
              onClick={() => click(5)}
              className={spotClasses("Grocery Stores", 5)}
              id="spot-5"
            >
              <circle cx="3227.07" cy="211.48" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M3233.55,198.5c0,0.46-0.15,0.84-0.44,1.14c-0.29,0.3-0.79,0.45-1.48,0.45h-8.21l-1.2,6.86
			c1.36-0.29,2.62-0.44,3.78-0.44c1.36,0,2.56,0.2,3.6,0.6s1.91,0.96,2.62,1.66c0.71,0.71,1.24,1.54,1.6,2.5
			c0.36,0.96,0.54,2.01,0.54,3.14c0,1.39-0.24,2.64-0.73,3.76s-1.15,2.08-2,2.87s-1.84,1.41-2.98,1.83s-2.38,0.64-3.7,0.64
			c-0.77,0-1.5-0.08-2.21-0.23c-0.71-0.15-1.36-0.36-1.97-0.61c-0.61-0.25-1.17-0.55-1.7-0.88c-0.52-0.33-0.99-0.68-1.39-1.04
			l0.99-1.39c0.22-0.32,0.51-0.47,0.88-0.47c0.23,0,0.5,0.09,0.81,0.28c0.31,0.19,0.68,0.4,1.12,0.63c0.44,0.23,0.95,0.44,1.54,0.63
			c0.59,0.19,1.29,0.28,2.11,0.28c0.91,0,1.73-0.15,2.46-0.44c0.73-0.29,1.35-0.71,1.87-1.25c0.52-0.54,0.92-1.19,1.19-1.94
			c0.28-0.75,0.42-1.6,0.42-2.54c0-0.82-0.12-1.55-0.36-2.21c-0.24-0.66-0.6-1.22-1.08-1.68c-0.48-0.46-1.08-0.82-1.8-1.08
			c-0.72-0.25-1.56-0.38-2.52-0.38c-0.66,0-1.34,0.05-2.04,0.16c-0.71,0.11-1.44,0.29-2.19,0.53l-2.04-0.6l2.12-12.23h12.39V198.5z"
                />
              </g>
            </g>
            <g
              onClick={() => click(4)}
              className={spotClasses("Grocery Stores", 4)}
              id="spot-4"
            >
              <circle cx="86.58" cy="1809.18" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M91.69,1811.49h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			H77.24c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V1811.49z M88.82,1800.71
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V1800.71z"
                />
              </g>
            </g>
            <g
              onClick={() => click(3)}
              className={spotClasses("Grocery Stores", 3)}
              id="spot-3"
            >
              <circle cx="163.65" cy="1722.73" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M163.6,1708.03c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C161.44,1708.19,162.48,1708.03,163.6,1708.03z"
                />
              </g>
            </g>
            <g
              onClick={() => click(2)}
              className={spotClasses("Grocery Stores", 2)}
              id="spot-2"
            >
              <circle cx="289.11" cy="1854.81" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M288.71,1840.12c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.92-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.82,0.29
			c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C286.55,1840.28,287.59,1840.12,288.71,1840.12z"
                />
              </g>
            </g>
            <g
              onClick={() => click(1)}
              className={spotClasses("Grocery Stores", 1)}
              id="spot-1"
            >
              <circle cx="2097.54" cy="841.55" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2091.41,850.81h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V850.81z"
                />
              </g>
            </g>
          </motion.g>
        )}

        {/* PUBS & RESTAURANTS */}
        {category === "Pubs & Restaurants" && (
          <motion.g
            id={`pubs & restaurants`}
            key="spots-pubs"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g
              onClick={() => click(21)}
              className={spotClasses("Pubs & Restaurants", 21)}
              id="spot-21"
            >
              <circle cx="3046.89" cy="130.75" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M3035.45,116.05c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7c-0.29,0.83-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03
			l-1.7-0.29c0.17-1.19,0.5-2.25,0.98-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C3033.29,116.21,3034.33,116.05,3035.45,116.05z"
                />
                <path
                  className={S.Text}
                  d="M3051.8,140.01h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V140.01z"
                />
              </g>
            </g>
            <g
              onClick={() => click(20)}
              className={spotClasses("Pubs & Restaurants", 20)}
              id="spot-20"
            >
              <circle cx="1687.78" cy="1650.69" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1676.34,1636c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C1674.18,1636.16,1675.22,1636,1676.34,1636z"
                />
                <path
                  className={S.Text}
                  d="M1707.53,1649.37c0,2.29-0.25,4.27-0.74,5.94c-0.49,1.67-1.17,3.06-2.02,4.15s-1.86,1.91-3.02,2.45
			c-1.16,0.54-2.41,0.8-3.73,0.8c-1.34,0-2.58-0.27-3.73-0.8c-1.15-0.54-2.15-1.35-3-2.45c-0.85-1.09-1.52-2.48-2.01-4.15
			c-0.49-1.67-0.73-3.65-0.73-5.94c0-2.29,0.24-4.27,0.73-5.95s1.16-3.07,2.01-4.17c0.85-1.1,1.85-1.92,3-2.46
			c1.15-0.54,2.39-0.8,3.73-0.8c1.33,0,2.57,0.27,3.73,0.8c1.16,0.54,2.17,1.35,3.02,2.46c0.85,1.1,1.52,2.49,2.02,4.17
			C1707.28,1645.1,1707.53,1647.09,1707.53,1649.37z M1704.15,1649.37c0-1.99-0.17-3.67-0.5-5.03c-0.33-1.36-0.79-2.45-1.35-3.28
			s-1.22-1.42-1.96-1.78c-0.74-0.36-1.51-0.54-2.32-0.54c-0.8,0-1.58,0.18-2.32,0.54s-1.39,0.95-1.95,1.78s-1.01,1.92-1.34,3.28
			c-0.33,1.36-0.5,3.03-0.5,5.03c0,2,0.17,3.67,0.5,5.02c0.33,1.35,0.78,2.44,1.34,3.27c0.56,0.83,1.21,1.42,1.95,1.78
			c0.74,0.36,1.51,0.54,2.32,0.54c0.8,0,1.58-0.18,2.32-0.54c0.74-0.36,1.4-0.95,1.96-1.78c0.57-0.83,1.02-1.92,1.35-3.27
			C1703.98,1653.04,1704.15,1651.37,1704.15,1649.37z"
                />
              </g>
            </g>
            <g
              onClick={() => click(19)}
              className={spotClasses("Pubs & Restaurants", 19)}
              id="spot-19"
            >
              <circle cx="75.63" cy="1261.4" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M58.46,1270.66h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			H58.46V1270.66z"
                />
                <path
                  className={S.Text}
                  d="M85.32,1262.42c-0.99,0-1.92-0.16-2.8-0.49c-0.88-0.33-1.66-0.81-2.33-1.45s-1.2-1.42-1.6-2.35
			s-0.59-2-0.59-3.2c0-1.14,0.21-2.21,0.64-3.21c0.43-1,1.02-1.87,1.79-2.61c0.77-0.74,1.68-1.33,2.74-1.75
			c1.06-0.43,2.22-0.64,3.48-0.64c1.25,0,2.39,0.21,3.41,0.62c1.02,0.41,1.9,0.99,2.63,1.73s1.29,1.63,1.69,2.66
			c0.4,1.03,0.59,2.18,0.59,3.43c0,0.75-0.07,1.47-0.21,2.14c-0.14,0.67-0.34,1.33-0.61,1.98c-0.27,0.65-0.59,1.29-0.96,1.93
			c-0.37,0.64-0.79,1.3-1.27,1.98l-6.37,9.2c-0.16,0.23-0.38,0.42-0.68,0.56s-0.63,0.21-1,0.21h-2.99l7.96-10.42
			c0.27-0.35,0.52-0.69,0.75-1c0.23-0.32,0.45-0.63,0.66-0.95c-0.67,0.54-1.42,0.94-2.26,1.22
			C87.15,1262.28,86.26,1262.42,85.32,1262.42z M91.85,1254.77c0-0.81-0.13-1.55-0.39-2.22s-0.62-1.23-1.09-1.69
			c-0.46-0.46-1.01-0.82-1.65-1.07c-0.64-0.25-1.34-0.37-2.11-0.37c-0.8,0-1.54,0.13-2.2,0.39s-1.23,0.62-1.7,1.09
			s-0.83,1.02-1.09,1.66c-0.26,0.65-0.38,1.35-0.38,2.12c0,0.83,0.12,1.57,0.36,2.22c0.24,0.65,0.58,1.2,1.02,1.65
			c0.44,0.45,0.99,0.79,1.62,1.02c0.64,0.23,1.35,0.35,2.14,0.35c0.88,0,1.65-0.14,2.33-0.43c0.68-0.29,1.24-0.67,1.71-1.14
			c0.46-0.47,0.81-1.02,1.06-1.64C91.73,1256.08,91.85,1255.44,91.85,1254.77z"
                />
              </g>
            </g>
            <g
              onClick={() => click(18)}
              className={spotClasses("Pubs & Restaurants", 18)}
              id="spot-18"
            >
              <circle cx="2088.15" cy="466.84" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2070.98,476.1h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V476.1z"
                />
                <path
                  className={S.Text}
                  d="M2098.38,478.87c-1.3,0-2.49-0.17-3.58-0.52c-1.08-0.35-2.01-0.84-2.79-1.49c-0.78-0.65-1.38-1.43-1.81-2.34
			c-0.43-0.92-0.64-1.95-0.64-3.09c0-1.68,0.44-3.06,1.31-4.15s2.13-1.86,3.76-2.33c-1.37-0.51-2.41-1.27-3.11-2.29
			c-0.7-1.02-1.05-2.23-1.05-3.64c0-0.96,0.19-1.86,0.57-2.7c0.38-0.84,0.91-1.57,1.61-2.19s1.52-1.11,2.49-1.46
			c0.97-0.35,2.05-0.53,3.24-0.53c1.18,0,2.26,0.18,3.23,0.53c0.97,0.35,1.81,0.84,2.5,1.46s1.23,1.35,1.61,2.19
			c0.38,0.84,0.57,1.74,0.57,2.7c0,1.41-0.35,2.62-1.06,3.64c-0.71,1.02-1.74,1.78-3.1,2.29c1.63,0.46,2.88,1.24,3.76,2.33
			c0.88,1.09,1.31,2.47,1.31,4.15c0,1.14-0.22,2.17-0.65,3.09c-0.43,0.92-1.03,1.7-1.81,2.34c-0.77,0.64-1.7,1.14-2.78,1.49
			S2099.69,478.87,2098.38,478.87z M2098.38,476.28c0.85,0,1.62-0.12,2.29-0.36s1.25-0.57,1.71-1c0.47-0.43,0.83-0.95,1.08-1.55
			c0.25-0.6,0.37-1.27,0.37-2c0-0.9-0.15-1.67-0.45-2.31s-0.7-1.16-1.2-1.56s-1.09-0.7-1.74-0.88c-0.66-0.19-1.34-0.28-2.06-0.28
			s-1.41,0.09-2.06,0.28c-0.66,0.19-1.24,0.48-1.74,0.88s-0.91,0.92-1.2,1.56c-0.3,0.64-0.45,1.41-0.45,2.31c0,0.73,0.12,1.4,0.37,2
			c0.25,0.6,0.61,1.12,1.08,1.55c0.47,0.43,1.04,0.77,1.71,1S2097.53,476.28,2098.38,476.28z M2098.38,463.73
			c0.85,0,1.58-0.13,2.18-0.39c0.6-0.26,1.09-0.61,1.47-1.04c0.38-0.43,0.65-0.93,0.82-1.49c0.17-0.56,0.25-1.14,0.25-1.73
			c0-0.61-0.1-1.18-0.3-1.72c-0.2-0.54-0.5-1-0.89-1.41c-0.4-0.4-0.89-0.72-1.48-0.95s-1.27-0.35-2.05-0.35s-1.46,0.12-2.05,0.35
			s-1.08,0.55-1.48,0.95s-0.69,0.87-0.89,1.41c-0.2,0.54-0.3,1.11-0.3,1.72c0,0.6,0.09,1.17,0.26,1.73s0.44,1.05,0.82,1.49
			c0.38,0.43,0.87,0.78,1.47,1.04S2097.53,463.73,2098.38,463.73z"
                />
              </g>
            </g>
            <g
              onClick={() => click(17)}
              className={spotClasses("Pubs & Restaurants", 17)}
              id="spot-17"
            >
              <circle cx="1931.51" cy="1080.35" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1914.34,1089.61h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V1089.61z"
                />
                <path
                  className={S.Text}
                  d="M1950.96,1065.95v1.46c0,0.41-0.05,0.75-0.14,1.02c-0.09,0.27-0.19,0.49-0.28,0.68l-10.82,21.84
			c-0.16,0.32-0.38,0.59-0.66,0.81c-0.28,0.23-0.66,0.34-1.13,0.34h-2.32l10.99-21.57c0.16-0.3,0.32-0.58,0.48-0.84
			c0.17-0.26,0.35-0.5,0.56-0.73h-13.65c-0.21,0-0.39-0.08-0.56-0.25s-0.25-0.35-0.25-0.56v-2.21H1950.96z"
                />
              </g>
            </g>
            <g
              onClick={() => click(16)}
              className={spotClasses("Pubs & Restaurants", 16)}
              id="spot-16"
            >
              <circle cx="2088.15" cy="798.69" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2070.98,807.96h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V807.96z"
                />
                <path
                  className={S.Text}
                  d="M2099.68,794.42c1.05,0,2.04,0.17,2.97,0.52s1.76,0.85,2.46,1.51c0.71,0.66,1.26,1.48,1.68,2.45
			c0.41,0.97,0.62,2.07,0.62,3.31c0,1.2-0.22,2.32-0.66,3.36c-0.44,1.03-1.05,1.93-1.83,2.7c-0.79,0.77-1.73,1.37-2.84,1.81
			c-1.11,0.44-2.32,0.66-3.65,0.66c-1.31,0-2.5-0.21-3.57-0.63c-1.06-0.42-1.97-1.02-2.73-1.79c-0.75-0.77-1.34-1.71-1.74-2.81
			c-0.41-1.1-0.61-2.33-0.61-3.7c0-1.14,0.26-2.36,0.77-3.65s1.32-2.68,2.43-4.16l6.62-8.92c0.17-0.22,0.41-0.4,0.71-0.56
			c0.3-0.15,0.65-0.23,1.04-0.23h2.88l-9.07,11.48c0.62-0.43,1.31-0.76,2.06-0.99C2097.99,794.54,2098.8,794.42,2099.68,794.42z
			 M2092.93,802.37c0,0.84,0.12,1.61,0.37,2.3c0.24,0.69,0.6,1.29,1.07,1.79c0.47,0.5,1.04,0.88,1.71,1.16
			c0.68,0.27,1.44,0.41,2.31,0.41c0.88,0,1.67-0.14,2.37-0.42c0.71-0.28,1.31-0.67,1.82-1.17s0.89-1.09,1.17-1.77
			c0.27-0.68,0.41-1.42,0.41-2.23c0-0.85-0.13-1.62-0.4-2.31c-0.27-0.69-0.64-1.27-1.13-1.75c-0.49-0.48-1.07-0.85-1.75-1.1
			s-1.43-0.38-2.24-0.38c-0.88,0-1.67,0.15-2.37,0.45c-0.71,0.3-1.3,0.7-1.8,1.21c-0.49,0.5-0.87,1.09-1.13,1.75
			C2093.06,800.96,2092.93,801.65,2092.93,802.37z"
                />
              </g>
            </g>
            <g
              onClick={() => click(15)}
              className={spotClasses("Pubs & Restaurants", 15)}
              id="spot-15"
            >
              <circle cx="64.5" cy="1892.42" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M47.33,1901.69h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			H47.33V1901.69z"
                />
                <path
                  className={S.Text}
                  d="M82.02,1879.44c0,0.46-0.15,0.84-0.44,1.14c-0.29,0.3-0.78,0.45-1.48,0.45h-8.21l-1.2,6.86
			c1.36-0.29,2.62-0.44,3.78-0.44c1.36,0,2.56,0.2,3.6,0.6c1.04,0.4,1.91,0.96,2.62,1.66c0.71,0.71,1.24,1.54,1.6,2.5
			s0.54,2.01,0.54,3.14c0,1.39-0.24,2.64-0.73,3.76c-0.49,1.12-1.15,2.08-2,2.87c-0.85,0.8-1.84,1.41-2.98,1.83
			c-1.14,0.43-2.38,0.64-3.7,0.64c-0.77,0-1.5-0.08-2.21-0.23c-0.71-0.15-1.36-0.36-1.97-0.61c-0.61-0.25-1.17-0.55-1.7-0.88
			c-0.52-0.33-0.99-0.68-1.39-1.04l0.99-1.39c0.22-0.32,0.51-0.47,0.88-0.47c0.23,0,0.5,0.09,0.81,0.28
			c0.31,0.19,0.68,0.4,1.12,0.63s0.95,0.44,1.54,0.63c0.59,0.19,1.29,0.28,2.11,0.28c0.91,0,1.73-0.15,2.46-0.44
			s1.35-0.71,1.87-1.25c0.52-0.54,0.92-1.19,1.19-1.94s0.42-1.6,0.42-2.54c0-0.82-0.12-1.55-0.36-2.21
			c-0.24-0.66-0.6-1.22-1.08-1.68c-0.48-0.46-1.08-0.82-1.8-1.08c-0.72-0.25-1.56-0.38-2.52-0.38c-0.66,0-1.34,0.05-2.04,0.16
			c-0.71,0.11-1.44,0.29-2.19,0.53l-2.04-0.6l2.12-12.23h12.39V1879.44z"
                />
              </g>
            </g>
            <g
              onClick={() => click(14)}
              className={spotClasses("Pubs & Restaurants", 14)}
              id="spot-14"
            >
              <circle cx="981.49" cy="1913.27" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M964.32,1922.53h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			h-14.01V1922.53z"
                />
                <path
                  className={S.Text}
                  d="M997.64,1915.58h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V1915.58z M994.77,1904.79
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V1904.79z"
                />
              </g>
            </g>
            <g
              onClick={() => click(13)}
              className={spotClasses("Pubs & Restaurants", 13)}
              id="spot-13"
            >
              <circle cx="3022.31" cy="201.52" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M3005.13,210.78h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V210.78z"
                />
                <path
                  className={S.Text}
                  d="M3033.29,186.82c1.11,0,2.13,0.16,3.06,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			s0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.39,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26s1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99c0.62,0.27,1.38,0.4,2.29,0.4
			c0.91,0,1.71-0.15,2.4-0.45s1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73
			c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1s-1.99-0.37-3.35-0.37v-2.37
			c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.92-0.95s0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86
			c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39c-0.41-0.37-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27
			c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.79,0.76-1.08,1.24c-0.29,0.48-0.51,1-0.66,1.56
			c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C3031.13,186.98,3032.17,186.82,3033.29,186.82z"
                />
              </g>
            </g>
            <g
              onClick={() => click(12)}
              className={spotClasses("Pubs & Restaurants", 12)}
              id="spot-12"
            >
              <circle cx="2956.81" cy="231.12" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2939.64,240.38h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V240.38z"
                />
                <path
                  className={S.Text}
                  d="M2967.45,216.42c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7c-0.29,0.83-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C2965.29,216.58,2966.33,216.42,2967.45,216.42z"
                />
              </g>
            </g>
            <g
              onClick={() => click(11)}
              className={spotClasses("Pubs & Restaurants", 11)}
              id="spot-11"
            >
              <circle cx="2956.81" cy="163.53" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2939.64,172.79h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V172.79z"
                />
                <path
                  className={S.Text}
                  d="M2961.72,172.79h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12c-0.15-0.08-0.25-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72
			h5.15v2.48h-14.01V172.79z"
                />
              </g>
            </g>
            <g
              onClick={() => click(10)}
              className={spotClasses("Pubs & Restaurants", 10)}
              id="spot-10"
            >
              <circle cx="396.1" cy="1878.81" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M378.93,1888.08h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			h-14.01V1888.08z"
                />
                <path
                  className={S.Text}
                  d="M415.84,1877.49c0,2.29-0.25,4.27-0.74,5.94c-0.49,1.67-1.17,3.06-2.02,4.15s-1.86,1.91-3.02,2.45
			c-1.16,0.54-2.41,0.8-3.73,0.8c-1.34,0-2.58-0.27-3.73-0.8c-1.15-0.54-2.15-1.35-3-2.45c-0.85-1.09-1.52-2.48-2.01-4.15
			c-0.49-1.67-0.73-3.65-0.73-5.94c0-2.29,0.24-4.27,0.73-5.95c0.49-1.68,1.16-3.07,2.01-4.17c0.85-1.1,1.85-1.92,3-2.46
			c1.15-0.54,2.39-0.8,3.73-0.8c1.33,0,2.57,0.27,3.73,0.8c1.16,0.54,2.17,1.35,3.02,2.46c0.85,1.1,1.52,2.49,2.02,4.17
			S415.84,1875.21,415.84,1877.49z M412.47,1877.49c0-1.99-0.17-3.67-0.5-5.03s-0.78-2.45-1.35-3.28c-0.57-0.83-1.22-1.42-1.96-1.78
			c-0.74-0.36-1.51-0.54-2.32-0.54c-0.8,0-1.58,0.18-2.32,0.54s-1.39,0.95-1.95,1.78c-0.56,0.83-1.01,1.92-1.34,3.28
			s-0.5,3.03-0.5,5.03c0,2,0.17,3.67,0.5,5.02s0.78,2.44,1.34,3.27c0.56,0.83,1.21,1.42,1.95,1.78c0.74,0.36,1.51,0.54,2.32,0.54
			c0.8,0,1.58-0.18,2.32-0.54c0.74-0.36,1.4-0.95,1.96-1.78c0.57-0.83,1.02-1.92,1.35-3.27S412.47,1879.49,412.47,1877.49z"
                />
              </g>
            </g>
            <g
              onClick={() => click(9)}
              className={spotClasses("Pubs & Restaurants", 9)}
              id="spot-9"
            >
              <circle cx="1304.45" cy="721.88" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1303.1,722.89c-0.99,0-1.92-0.16-2.8-0.49c-0.88-0.33-1.66-0.81-2.33-1.45s-1.2-1.42-1.6-2.35
			s-0.59-2-0.59-3.2c0-1.14,0.21-2.21,0.64-3.21c0.43-1,1.02-1.87,1.79-2.61c0.77-0.74,1.68-1.33,2.74-1.75
			c1.06-0.43,2.22-0.64,3.48-0.64c1.25,0,2.39,0.21,3.41,0.62c1.02,0.41,1.9,0.99,2.63,1.73s1.29,1.63,1.69,2.66
			c0.4,1.03,0.59,2.18,0.59,3.43c0,0.75-0.07,1.47-0.21,2.14c-0.14,0.67-0.34,1.33-0.61,1.98c-0.27,0.65-0.59,1.29-0.96,1.93
			c-0.37,0.64-0.79,1.3-1.27,1.98l-6.37,9.2c-0.16,0.23-0.38,0.42-0.68,0.56s-0.63,0.21-1,0.21h-2.99l7.96-10.42
			c0.27-0.35,0.52-0.69,0.75-1c0.23-0.32,0.45-0.63,0.66-0.95c-0.67,0.54-1.42,0.94-2.26,1.22
			C1304.93,722.75,1304.04,722.89,1303.1,722.89z M1309.64,715.25c0-0.81-0.13-1.55-0.39-2.22s-0.62-1.23-1.09-1.69
			c-0.46-0.46-1.01-0.82-1.65-1.07c-0.64-0.25-1.34-0.37-2.11-0.37c-0.8,0-1.54,0.13-2.2,0.39s-1.23,0.62-1.7,1.09
			s-0.83,1.02-1.09,1.66c-0.26,0.65-0.38,1.35-0.38,2.12c0,0.83,0.12,1.57,0.36,2.22c0.24,0.65,0.58,1.2,1.02,1.65
			c0.44,0.45,0.99,0.79,1.62,1.02c0.64,0.23,1.35,0.35,2.14,0.35c0.88,0,1.65-0.14,2.33-0.43c0.68-0.29,1.24-0.67,1.71-1.14
			c0.46-0.47,0.81-1.02,1.06-1.64C1309.51,716.56,1309.64,715.92,1309.64,715.25z"
                />
              </g>
            </g>
            <g
              onClick={() => click(8)}
              className={spotClasses("Pubs & Restaurants", 8)}
              id="spot-8"
            >
              <circle cx="1725.71" cy="1677.24" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1724.91,1689.28c-1.3,0-2.49-0.17-3.58-0.52s-2.01-0.84-2.79-1.49c-0.78-0.65-1.38-1.43-1.81-2.34
			c-0.43-0.92-0.64-1.95-0.64-3.09c0-1.68,0.44-3.06,1.31-4.15s2.13-1.86,3.76-2.33c-1.38-0.51-2.41-1.27-3.11-2.29
			s-1.05-2.23-1.05-3.64c0-0.96,0.19-1.86,0.57-2.7c0.38-0.84,0.91-1.57,1.61-2.19s1.52-1.11,2.49-1.46
			c0.97-0.35,2.05-0.53,3.24-0.53c1.18,0,2.26,0.18,3.23,0.53c0.97,0.35,1.81,0.84,2.5,1.46s1.23,1.35,1.61,2.19
			c0.38,0.84,0.57,1.74,0.57,2.7c0,1.41-0.35,2.62-1.06,3.64c-0.71,1.02-1.74,1.78-3.1,2.29c1.63,0.46,2.88,1.24,3.76,2.33
			s1.31,2.47,1.31,4.15c0,1.14-0.22,2.17-0.65,3.09c-0.43,0.92-1.03,1.7-1.81,2.34c-0.77,0.64-1.7,1.14-2.78,1.49
			C1727.4,1689.1,1726.21,1689.28,1724.91,1689.28z M1724.91,1686.69c0.85,0,1.62-0.12,2.29-0.36c0.68-0.24,1.25-0.57,1.72-1
			c0.47-0.43,0.83-0.95,1.08-1.55c0.25-0.6,0.37-1.27,0.37-2c0-0.9-0.15-1.67-0.45-2.31c-0.3-0.64-0.7-1.16-1.21-1.56
			s-1.08-0.7-1.74-0.88s-1.34-0.28-2.06-0.28s-1.4,0.09-2.06,0.28s-1.24,0.48-1.74,0.88c-0.5,0.4-0.91,0.92-1.21,1.56
			s-0.45,1.41-0.45,2.31c0,0.73,0.12,1.4,0.37,2c0.25,0.6,0.61,1.12,1.08,1.55c0.47,0.43,1.04,0.77,1.71,1
			C1723.3,1686.57,1724.06,1686.69,1724.91,1686.69z M1724.91,1674.13c0.85,0,1.58-0.13,2.18-0.39c0.6-0.26,1.09-0.61,1.47-1.04
			c0.38-0.43,0.65-0.93,0.82-1.49c0.17-0.56,0.26-1.14,0.26-1.73c0-0.61-0.1-1.18-0.3-1.72c-0.2-0.54-0.5-1-0.89-1.41
			s-0.89-0.72-1.48-0.95c-0.59-0.23-1.28-0.35-2.05-0.35s-1.46,0.12-2.05,0.35s-1.08,0.55-1.48,0.95s-0.69,0.87-0.89,1.41
			c-0.2,0.54-0.3,1.11-0.3,1.72c0,0.6,0.08,1.17,0.26,1.73c0.17,0.56,0.44,1.05,0.82,1.49c0.38,0.43,0.87,0.78,1.47,1.04
			C1723.33,1674,1724.06,1674.13,1724.91,1674.13z"
                />
              </g>
            </g>
            <g
              onClick={() => click(7)}
              className={spotClasses("Pubs & Restaurants", 7)}
              id="spot-7"
            >
              <circle cx="2046.9" cy="1011.65" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2055.31,997.24v1.46c0,0.41-0.04,0.75-0.14,1.02c-0.09,0.27-0.19,0.49-0.28,0.68l-10.82,21.84
			c-0.16,0.32-0.38,0.59-0.66,0.81c-0.28,0.23-0.66,0.34-1.13,0.34h-2.32l10.99-21.57c0.16-0.3,0.32-0.58,0.48-0.84
			c0.16-0.26,0.35-0.5,0.56-0.73h-13.65c-0.21,0-0.39-0.08-0.56-0.25c-0.16-0.16-0.25-0.35-0.25-0.56v-2.21H2055.31z"
                />
              </g>
            </g>
            <g
              onClick={() => click(6)}
              className={spotClasses("Pubs & Restaurants", 6)}
              id="spot-6"
            >
              <circle cx="2178.57" cy="1011.65" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2179.06,1007.37c1.05,0,2.04,0.17,2.97,0.52c0.94,0.35,1.76,0.85,2.46,1.51c0.71,0.66,1.27,1.48,1.68,2.45
			c0.41,0.97,0.62,2.07,0.62,3.31c0,1.2-0.22,2.32-0.66,3.36c-0.44,1.03-1.05,1.93-1.83,2.7c-0.78,0.77-1.73,1.37-2.84,1.81
			c-1.11,0.44-2.32,0.66-3.65,0.66c-1.31,0-2.5-0.21-3.57-0.63c-1.07-0.42-1.97-1.02-2.73-1.79s-1.33-1.71-1.74-2.81
			c-0.41-1.1-0.61-2.33-0.61-3.7c0-1.14,0.25-2.36,0.77-3.65c0.51-1.29,1.32-2.68,2.43-4.16l6.62-8.92c0.17-0.22,0.41-0.4,0.71-0.56
			s0.65-0.23,1.04-0.23h2.88l-9.07,11.48c0.62-0.43,1.31-0.76,2.06-0.99C2177.37,1007.49,2178.19,1007.37,2179.06,1007.37z
			 M2172.31,1015.33c0,0.84,0.12,1.61,0.36,2.3c0.24,0.69,0.6,1.29,1.07,1.79c0.47,0.5,1.04,0.88,1.72,1.16
			c0.67,0.27,1.44,0.41,2.31,0.41c0.88,0,1.67-0.14,2.37-0.42c0.71-0.28,1.31-0.67,1.82-1.17c0.5-0.5,0.89-1.09,1.17-1.77
			c0.27-0.68,0.41-1.42,0.41-2.23c0-0.85-0.13-1.62-0.4-2.31c-0.27-0.69-0.65-1.27-1.13-1.75c-0.49-0.48-1.07-0.85-1.75-1.1
			c-0.68-0.26-1.43-0.38-2.25-0.38c-0.88,0-1.67,0.15-2.37,0.45c-0.71,0.3-1.3,0.7-1.8,1.21c-0.49,0.5-0.87,1.09-1.13,1.75
			C2172.44,1013.92,2172.31,1014.61,2172.31,1015.33z"
                />
              </g>
            </g>
          </motion.g>
        )}

        {/* WINERIES */}
        {category === "Wineries" && (
          <motion.g
            id={`wineries`}
            key="spots-wineries"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g
              onClick={() => click(34)}
              className={spotClasses("Wineries", 34)}
              id="spot-34"
            >
              <circle cx="279.91" cy="2019.87" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M268.82,2005.17c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C266.65,2005.33,267.7,2005.17,268.82,2005.17z"
                />
                <path
                  className={S.Text}
                  d="M296.06,2022.18h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V2022.18z M293.19,2011.39
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V2011.39z"
                />
              </g>
            </g>
            <g
              onClick={() => click(33)}
              className={spotClasses("Wineries", 33)}
              id="spot-33"
            >
              <circle cx="2134.12" cy="528.37" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2123.03,513.68c1.11,0,2.13,0.16,3.07,0.47s1.74,0.77,2.42,1.35s1.2,1.29,1.58,2.12s0.57,1.75,0.57,2.76
			c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25c-0.56,0.34-1.19,0.61-1.88,0.82
			c1.7,0.45,2.98,1.2,3.84,2.26s1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25c-0.46,0.96-1.08,1.78-1.87,2.46
			c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52s-1.82-0.82-2.5-1.43
			s-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16c0.24,0,0.46,0.05,0.65,0.15
			c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17c0.17,0.35,0.38,0.75,0.62,1.2
			s0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4s1.71-0.15,2.4-0.45s1.26-0.68,1.72-1.16
			c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.79-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			c-0.41-0.37-0.9-0.64-1.47-0.82s-1.18-0.27-1.83-0.27c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C2120.86,513.84,2121.91,513.68,2123.03,513.68z"
                />
                <path
                  className={S.Text}
                  d="M2145.1,513.68c1.11,0,2.13,0.16,3.07,0.47s1.74,0.77,2.42,1.35s1.2,1.29,1.58,2.12s0.57,1.75,0.57,2.76
			c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25c-0.56,0.34-1.19,0.61-1.88,0.82
			c1.7,0.45,2.98,1.2,3.84,2.26s1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25c-0.46,0.96-1.08,1.78-1.87,2.46
			c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52s-1.82-0.82-2.5-1.43
			s-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16c0.24,0,0.46,0.05,0.65,0.15
			c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17c0.17,0.35,0.38,0.75,0.62,1.2
			s0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4s1.71-0.15,2.4-0.45s1.26-0.68,1.72-1.16
			c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.79-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			c-0.41-0.37-0.9-0.64-1.47-0.82s-1.18-0.27-1.83-0.27c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C2142.94,513.84,2143.99,513.68,2145.1,513.68z"
                />
              </g>
            </g>
            <g
              onClick={() => click(32)}
              className={spotClasses("Wineries", 32)}
              id="spot-32"
            >
              <circle cx="129.09" cy="2178.68" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M118,2163.98c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C115.83,2164.14,116.88,2163.98,118,2163.98z"
                />
                <path
                  className={S.Text}
                  d="M139.73,2163.98c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.92-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.82,0.29
			c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C137.57,2164.14,138.61,2163.98,139.73,2163.98z"
                />
              </g>
            </g>
            <g
              onClick={() => click(31)}
              className={spotClasses("Wineries", 31)}
              id="spot-31"
            >
              <circle cx="1341.82" cy="2137.54" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1330.73,2122.84c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C1328.56,2123,1329.61,2122.84,1330.73,2122.84z"
                />
                <path
                  className={S.Text}
                  d="M1346.73,2146.8h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			h-14.01V2146.8z"
                />
              </g>
            </g>
            <g
              onClick={() => click(30)}
              className={spotClasses("Wineries", 30)}
              id="spot-30"
            >
              <circle cx="1000.91" cy="1979.9" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M989.81,1965.2c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C987.65,1965.36,988.69,1965.2,989.81,1965.2z"
                />
                <path
                  className={S.Text}
                  d="M1020.65,1978.57c0,2.29-0.25,4.27-0.74,5.94c-0.49,1.67-1.17,3.06-2.02,4.15s-1.86,1.91-3.02,2.45
			c-1.16,0.54-2.41,0.8-3.73,0.8c-1.34,0-2.58-0.27-3.73-0.8c-1.15-0.54-2.15-1.35-3-2.45c-0.85-1.09-1.52-2.48-2.01-4.15
			c-0.49-1.67-0.73-3.65-0.73-5.94c0-2.29,0.24-4.27,0.73-5.95c0.49-1.68,1.16-3.07,2.01-4.17c0.85-1.1,1.85-1.92,3-2.46
			c1.15-0.54,2.39-0.8,3.73-0.8c1.33,0,2.57,0.27,3.73,0.8c1.16,0.54,2.17,1.35,3.02,2.46c0.85,1.1,1.52,2.49,2.02,4.17
			S1020.65,1976.29,1020.65,1978.57z M1017.27,1978.57c0-1.99-0.17-3.67-0.5-5.03s-0.78-2.45-1.35-3.28
			c-0.57-0.83-1.22-1.42-1.96-1.78c-0.74-0.36-1.51-0.54-2.32-0.54c-0.8,0-1.58,0.18-2.32,0.54s-1.39,0.95-1.95,1.78
			c-0.56,0.83-1.01,1.92-1.34,3.28s-0.5,3.03-0.5,5.03c0,2,0.17,3.67,0.5,5.02s0.78,2.44,1.34,3.27c0.56,0.83,1.21,1.42,1.95,1.78
			c0.74,0.36,1.51,0.54,2.32,0.54c0.8,0,1.58-0.18,2.32-0.54c0.74-0.36,1.4-0.95,1.96-1.78c0.57-0.83,1.02-1.92,1.35-3.27
			S1017.27,1980.57,1017.27,1978.57z"
                />
              </g>
            </g>
            <g
              onClick={() => click(29)}
              className={spotClasses("Wineries", 29)}
              id="spot-29"
            >
              <circle cx="1747.59" cy="1616.44" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1736.15,1601.74c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C1733.98,1601.9,1735.03,1601.74,1736.15,1601.74z"
                />
                <path
                  className={S.Text}
                  d="M1757.28,1617.45c-0.99,0-1.92-0.16-2.8-0.49c-0.88-0.33-1.66-0.81-2.33-1.45s-1.2-1.42-1.6-2.35
			s-0.59-2-0.59-3.2c0-1.14,0.21-2.21,0.64-3.21s1.02-1.87,1.79-2.61c0.77-0.74,1.68-1.33,2.74-1.75s2.22-0.64,3.49-0.64
			c1.25,0,2.39,0.21,3.41,0.62c1.02,0.41,1.9,0.99,2.63,1.73c0.73,0.74,1.29,1.63,1.69,2.66s0.59,2.18,0.59,3.43
			c0,0.75-0.07,1.47-0.21,2.14c-0.14,0.67-0.34,1.33-0.61,1.98c-0.27,0.65-0.59,1.29-0.96,1.93c-0.37,0.64-0.79,1.3-1.27,1.98
			l-6.37,9.2c-0.16,0.23-0.38,0.42-0.68,0.56s-0.63,0.21-1,0.21h-2.99l7.96-10.42c0.27-0.35,0.52-0.69,0.75-1s0.45-0.63,0.66-0.95
			c-0.67,0.54-1.42,0.94-2.26,1.22C1759.1,1617.31,1758.21,1617.45,1757.28,1617.45z M1763.81,1609.81c0-0.81-0.13-1.55-0.39-2.22
			s-0.62-1.23-1.08-1.69c-0.46-0.46-1.01-0.82-1.65-1.07c-0.64-0.25-1.34-0.37-2.11-0.37c-0.8,0-1.54,0.13-2.2,0.39
			s-1.23,0.62-1.7,1.09c-0.47,0.46-0.83,1.02-1.09,1.66c-0.25,0.65-0.38,1.35-0.38,2.12c0,0.83,0.12,1.57,0.36,2.22
			c0.24,0.65,0.58,1.2,1.02,1.65c0.44,0.45,0.99,0.79,1.62,1.02c0.64,0.23,1.35,0.35,2.14,0.35c0.88,0,1.65-0.14,2.33-0.43
			c0.67-0.29,1.24-0.67,1.71-1.14c0.46-0.47,0.81-1.02,1.06-1.64C1763.69,1611.12,1763.81,1610.48,1763.81,1609.81z"
                />
              </g>
            </g>
            <g
              onClick={() => click(28)}
              className={spotClasses("Wineries", 28)}
              id="spot-28"
            >
              <circle cx="2215.18" cy="341.99" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2203.74,327.29c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C2201.57,327.45,2202.62,327.29,2203.74,327.29z"
                />
                <path
                  className={S.Text}
                  d="M2225.41,354.02c-1.3,0-2.49-0.17-3.58-0.52s-2.01-0.84-2.79-1.49c-0.78-0.65-1.38-1.43-1.81-2.34
			c-0.43-0.92-0.64-1.95-0.64-3.09c0-1.68,0.44-3.06,1.31-4.15s2.13-1.86,3.76-2.33c-1.38-0.51-2.41-1.27-3.11-2.29
			s-1.05-2.23-1.05-3.64c0-0.96,0.19-1.86,0.57-2.7c0.38-0.84,0.91-1.57,1.61-2.19s1.52-1.11,2.49-1.46
			c0.97-0.35,2.05-0.53,3.24-0.53c1.18,0,2.26,0.18,3.23,0.53c0.97,0.35,1.81,0.84,2.5,1.46s1.23,1.35,1.61,2.19
			c0.38,0.84,0.57,1.74,0.57,2.7c0,1.41-0.35,2.62-1.06,3.64c-0.71,1.02-1.74,1.78-3.1,2.29c1.63,0.46,2.88,1.24,3.76,2.33
			s1.31,2.47,1.31,4.15c0,1.14-0.22,2.17-0.65,3.09c-0.43,0.92-1.03,1.7-1.81,2.34c-0.77,0.64-1.7,1.14-2.78,1.49
			C2227.91,353.85,2226.71,354.02,2225.41,354.02z M2225.41,351.43c0.85,0,1.62-0.12,2.29-0.36c0.68-0.24,1.25-0.57,1.72-1
			c0.47-0.43,0.83-0.95,1.08-1.55c0.25-0.6,0.37-1.27,0.37-2c0-0.9-0.15-1.67-0.45-2.31c-0.3-0.64-0.7-1.16-1.21-1.56
			s-1.08-0.7-1.74-0.88s-1.34-0.28-2.06-0.28s-1.4,0.09-2.06,0.28s-1.24,0.48-1.74,0.88c-0.5,0.4-0.91,0.92-1.21,1.56
			s-0.45,1.41-0.45,2.31c0,0.73,0.12,1.4,0.37,2c0.25,0.6,0.61,1.12,1.08,1.55c0.47,0.43,1.04,0.77,1.71,1
			C2223.8,351.31,2224.56,351.43,2225.41,351.43z M2225.41,338.88c0.85,0,1.58-0.13,2.18-0.39c0.6-0.26,1.09-0.61,1.47-1.04
			c0.38-0.43,0.65-0.93,0.82-1.49c0.17-0.56,0.26-1.14,0.26-1.73c0-0.61-0.1-1.18-0.3-1.72c-0.2-0.54-0.5-1-0.89-1.41
			s-0.89-0.72-1.48-0.95c-0.59-0.23-1.28-0.35-2.05-0.35s-1.46,0.12-2.05,0.35s-1.08,0.55-1.48,0.95s-0.69,0.87-0.89,1.41
			c-0.2,0.54-0.3,1.11-0.3,1.72c0,0.6,0.08,1.17,0.26,1.73c0.17,0.56,0.44,1.05,0.82,1.49c0.38,0.43,0.87,0.78,1.47,1.04
			C2223.83,338.75,2224.56,338.88,2225.41,338.88z"
                />
              </g>
            </g>
            <g
              onClick={() => click(27)}
              className={spotClasses("Wineries", 27)}
              id="spot-27"
            >
              <circle cx="1905.61" cy="1463.64" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1894.17,1448.94c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.83,0.29
			c-0.56,0.19-1.05,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.1,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C1892,1449.1,1893.05,1448.94,1894.17,1448.94z"
                />
                <path
                  className={S.Text}
                  d="M1925.06,1449.23v1.46c0,0.41-0.05,0.75-0.14,1.02c-0.09,0.27-0.19,0.49-0.28,0.68l-10.82,21.84
			c-0.16,0.32-0.38,0.59-0.66,0.81c-0.28,0.23-0.66,0.34-1.13,0.34h-2.32l10.98-21.57c0.16-0.3,0.32-0.58,0.48-0.84
			c0.17-0.26,0.35-0.5,0.56-0.73h-13.65c-0.21,0-0.39-0.08-0.56-0.25c-0.16-0.16-0.25-0.35-0.25-0.56v-2.21H1925.06z"
                />
              </g>
            </g>
            <g
              onClick={() => click(26)}
              className={spotClasses("Wineries", 26)}
              id="spot-26"
            >
              <circle cx="1956.48" cy="605.24" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1945.04,590.55c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C1942.88,590.71,1943.92,590.55,1945.04,590.55z"
                />
                <path
                  className={S.Text}
                  d="M1968.01,600.97c1.05,0,2.04,0.17,2.97,0.52c0.94,0.35,1.76,0.85,2.46,1.51c0.71,0.66,1.27,1.48,1.68,2.45
			c0.41,0.97,0.62,2.07,0.62,3.31c0,1.2-0.22,2.32-0.66,3.36c-0.44,1.03-1.05,1.93-1.83,2.7c-0.78,0.77-1.73,1.37-2.84,1.81
			c-1.11,0.44-2.32,0.66-3.65,0.66c-1.31,0-2.5-0.21-3.57-0.63c-1.07-0.42-1.97-1.02-2.73-1.79s-1.33-1.71-1.74-2.81
			c-0.41-1.1-0.61-2.33-0.61-3.7c0-1.14,0.25-2.36,0.77-3.65c0.51-1.29,1.32-2.68,2.43-4.16l6.62-8.92c0.17-0.22,0.41-0.4,0.71-0.56
			s0.65-0.23,1.04-0.23h2.88l-9.07,11.48c0.62-0.43,1.31-0.76,2.06-0.99C1966.32,601.08,1967.14,600.97,1968.01,600.97z
			 M1961.26,608.92c0,0.84,0.12,1.61,0.36,2.3c0.24,0.69,0.6,1.29,1.07,1.79c0.47,0.5,1.04,0.88,1.72,1.16
			c0.67,0.27,1.44,0.41,2.31,0.41c0.88,0,1.67-0.14,2.37-0.42c0.71-0.28,1.31-0.67,1.82-1.17c0.5-0.5,0.89-1.09,1.17-1.77
			c0.27-0.68,0.41-1.42,0.41-2.23c0-0.85-0.13-1.62-0.4-2.31c-0.27-0.69-0.65-1.27-1.13-1.75c-0.49-0.48-1.07-0.85-1.75-1.1
			c-0.68-0.26-1.43-0.38-2.25-0.38c-0.88,0-1.67,0.15-2.37,0.45c-0.71,0.3-1.3,0.7-1.8,1.21c-0.49,0.5-0.87,1.09-1.13,1.75
			C1961.39,607.51,1961.26,608.2,1961.26,608.92z"
                />
              </g>
            </g>
            <g
              onClick={() => click(25)}
              className={spotClasses("Wineries", 25)}
              id="spot-25"
            >
              <circle cx="1892.32" cy="1018.45" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1880.88,1003.75c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32s0.98-0.12,1.44-0.12h8.76c0.35,0,0.63,0.1,0.84,0.31
			s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67l8.38-8.41
			c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03
			l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C1878.72,1003.91,1879.76,1003.75,1880.88,1003.75z"
                />
                <path
                  className={S.Text}
                  d="M1909.84,1005.47c0,0.46-0.15,0.84-0.44,1.14c-0.29,0.3-0.79,0.45-1.48,0.45h-8.21l-1.2,6.86
			c1.36-0.29,2.62-0.44,3.78-0.44c1.36,0,2.56,0.2,3.6,0.6s1.91,0.96,2.62,1.66c0.71,0.71,1.24,1.54,1.6,2.5
			c0.36,0.96,0.54,2.01,0.54,3.14c0,1.39-0.24,2.64-0.73,3.76s-1.15,2.08-2,2.87s-1.84,1.41-2.98,1.83s-2.38,0.64-3.7,0.64
			c-0.77,0-1.5-0.08-2.21-0.23c-0.71-0.15-1.36-0.36-1.97-0.61c-0.61-0.25-1.17-0.55-1.7-0.88c-0.52-0.33-0.99-0.68-1.39-1.04
			l0.99-1.39c0.22-0.32,0.51-0.47,0.88-0.47c0.23,0,0.5,0.09,0.81,0.28c0.31,0.19,0.68,0.4,1.12,0.63c0.44,0.23,0.95,0.44,1.54,0.63
			c0.59,0.19,1.29,0.28,2.11,0.28c0.91,0,1.73-0.15,2.46-0.44c0.73-0.29,1.35-0.71,1.87-1.25c0.52-0.54,0.92-1.19,1.19-1.94
			c0.28-0.75,0.42-1.6,0.42-2.54c0-0.82-0.12-1.55-0.36-2.21c-0.24-0.66-0.6-1.22-1.08-1.68c-0.48-0.46-1.08-0.82-1.8-1.08
			c-0.72-0.25-1.56-0.38-2.52-0.38c-0.66,0-1.34,0.05-2.04,0.16c-0.71,0.11-1.44,0.29-2.19,0.53l-2.04-0.6l2.12-12.23h12.39V1005.47
			z"
                />
              </g>
            </g>
            <g
              onClick={() => click(24)}
              className={spotClasses("Wineries", 24)}
              id="spot-24"
            >
              <circle cx="2052.93" cy="951.73" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2041.49,937.03c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32s0.98-0.12,1.44-0.12h8.76c0.35,0,0.63,0.1,0.84,0.31
			s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67l8.38-8.41
			c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03
			l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C2039.33,937.19,2040.37,937.03,2041.49,937.03z"
                />
                <path
                  className={S.Text}
                  d="M2069.08,954.04h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49s-0.29,0.2-0.54,0.2h-3.25v6.88h-2.87v-6.88h-11.59
			c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V954.04z M2066.22,943.25
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V943.25z"
                />
              </g>
            </g>
            <g
              onClick={() => click(23)}
              className={spotClasses("Wineries", 23)}
              id="spot-23"
            >
              <circle cx="2046.9" cy="1055.06" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2035.46,1040.36c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.83,0.29
			c-0.56,0.19-1.05,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.1,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C2033.3,1040.52,2034.34,1040.36,2035.46,1040.36z"
                />
                <path
                  className={S.Text}
                  d="M2057.88,1040.36c1.11,0,2.13,0.16,3.07,0.47s1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			c-0.99-0.35-1.82-0.82-2.5-1.43s-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.25-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2s0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4s1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93
			c-0.19-0.58-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35
			c0.79-0.23,1.43-0.55,1.93-0.95c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97
			c-0.24-0.56-0.56-1.02-0.98-1.39s-0.9-0.64-1.47-0.82s-1.18-0.27-1.83-0.27s-1.27,0.1-1.83,0.29c-0.56,0.19-1.05,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C2055.72,1040.52,2056.77,1040.36,2057.88,1040.36z"
                />
              </g>
            </g>
            <g
              onClick={() => click(22)}
              className={spotClasses("Wineries", 22)}
              id="spot-22"
            >
              <circle cx="2178.57" cy="1055.06" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2167.13,1040.36c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.04-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.37-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.83,0.29
			c-0.56,0.19-1.05,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.1,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.2,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C2164.97,1040.52,2166.01,1040.36,2167.13,1040.36z"
                />
                <path
                  className={S.Text}
                  d="M2189.21,1040.36c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32s0.98-0.12,1.44-0.12h8.76c0.35,0,0.63,0.1,0.84,0.31
			s0.31,0.47,0.31,0.8v1.97h-17.45v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67l8.38-8.41
			c0.69-0.71,1.33-1.38,1.9-2.03s1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16s-0.12-1.44-0.36-2.02
			c-0.24-0.58-0.58-1.05-1-1.43c-0.43-0.38-0.93-0.66-1.5-0.85s-1.19-0.28-1.84-0.28s-1.27,0.1-1.82,0.29
			c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23c-0.3,0.47-0.51,1-0.63,1.57c-0.1,0.35-0.24,0.61-0.44,0.77
			c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C2187.04,1040.52,2188.09,1040.36,2189.21,1040.36z"
                />
              </g>
            </g>
          </motion.g>
        )}

        {/* PARKS & RECREATION */}
        {category === "Parks & Recreation" && (
          <motion.g
            id={`parks & recreation`}
            key="spots-parks"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g
              onClick={() => click(46)}
              className={spotClasses("Parks & Recreation", 46)}
              id="spot-46"
            >
              <circle cx="2438.63" cy="374.89" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2432.7,376.2h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49s-0.29,0.2-0.54,0.2h-3.25v6.88h-2.87v-6.88h-11.59
			c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V376.2z M2429.83,365.41
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V365.41z"
                />
                <path
                  className={S.Text}
                  d="M2450.16,369.61c1.05,0,2.04,0.17,2.97,0.52s1.76,0.85,2.46,1.51c0.71,0.66,1.26,1.48,1.68,2.45
			c0.41,0.97,0.62,2.07,0.62,3.31c0,1.2-0.22,2.32-0.66,3.36c-0.44,1.03-1.05,1.93-1.83,2.7c-0.79,0.77-1.73,1.37-2.84,1.81
			c-1.11,0.44-2.32,0.66-3.65,0.66c-1.31,0-2.5-0.21-3.57-0.63c-1.06-0.42-1.97-1.02-2.73-1.79c-0.75-0.77-1.34-1.71-1.74-2.81
			c-0.41-1.1-0.61-2.33-0.61-3.7c0-1.14,0.26-2.36,0.77-3.65s1.32-2.68,2.43-4.16l6.62-8.92c0.17-0.22,0.41-0.4,0.71-0.56
			c0.3-0.15,0.65-0.23,1.04-0.23h2.88l-9.07,11.48c0.62-0.43,1.31-0.76,2.06-0.99C2448.47,369.73,2449.29,369.61,2450.16,369.61z
			 M2443.41,377.57c0,0.84,0.12,1.61,0.37,2.3c0.24,0.69,0.6,1.29,1.07,1.79c0.47,0.5,1.04,0.88,1.71,1.16
			c0.68,0.27,1.44,0.41,2.31,0.41c0.88,0,1.67-0.14,2.37-0.42c0.71-0.28,1.31-0.67,1.82-1.17s0.89-1.09,1.17-1.77
			c0.27-0.68,0.41-1.42,0.41-2.23c0-0.85-0.13-1.62-0.4-2.31c-0.27-0.69-0.64-1.27-1.13-1.75c-0.49-0.48-1.07-0.85-1.75-1.1
			s-1.43-0.38-2.24-0.38c-0.88,0-1.67,0.15-2.37,0.45c-0.71,0.3-1.3,0.7-1.8,1.21c-0.49,0.5-0.87,1.09-1.13,1.75
			C2443.54,376.16,2443.41,376.85,2443.41,377.57z"
                />
              </g>
            </g>
            <g
              onClick={() => click(45)}
              className={spotClasses("Parks & Recreation", 45)}
              id="spot-45"
            >
              <circle cx="1667.91" cy="61.21" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1661.98,62.52h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49s-0.29,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88h-11.59
			c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V62.52z M1659.11,51.74
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V51.74z"
                />
                <path
                  className={S.Text}
                  d="M1685.42,47.23c0,0.46-0.15,0.84-0.44,1.14c-0.29,0.3-0.78,0.45-1.48,0.45h-8.21l-1.21,6.86
			c1.36-0.29,2.62-0.44,3.78-0.44c1.36,0,2.56,0.2,3.6,0.6c1.04,0.4,1.91,0.96,2.62,1.66c0.71,0.71,1.24,1.54,1.6,2.5
			c0.36,0.96,0.54,2.01,0.54,3.14c0,1.39-0.24,2.64-0.73,3.76s-1.15,2.08-2,2.87s-1.84,1.41-2.98,1.83
			c-1.14,0.43-2.38,0.64-3.71,0.64c-0.77,0-1.5-0.08-2.21-0.23c-0.71-0.15-1.36-0.36-1.97-0.61c-0.61-0.25-1.17-0.55-1.7-0.88
			c-0.52-0.33-0.99-0.68-1.39-1.04l0.98-1.39c0.22-0.32,0.51-0.47,0.88-0.47c0.23,0,0.5,0.09,0.81,0.28
			c0.31,0.19,0.68,0.4,1.12,0.63s0.95,0.44,1.54,0.63c0.59,0.19,1.29,0.28,2.11,0.28c0.91,0,1.73-0.15,2.46-0.44
			c0.73-0.29,1.35-0.71,1.87-1.25c0.52-0.54,0.92-1.19,1.19-1.94c0.28-0.75,0.42-1.6,0.42-2.54c0-0.82-0.12-1.55-0.36-2.21
			c-0.24-0.66-0.6-1.22-1.08-1.68c-0.48-0.46-1.08-0.82-1.8-1.08c-0.72-0.25-1.56-0.38-2.52-0.38c-0.66,0-1.34,0.05-2.04,0.16
			c-0.71,0.11-1.44,0.29-2.19,0.53l-2.04-0.6l2.12-12.23h12.39V47.23z"
                />
              </g>
            </g>
            <g
              onClick={() => click(44)}
              className={spotClasses("Parks & Recreation", 44)}
              id="spot-44"
            >
              <circle cx="279.34" cy="778.12" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M273.41,779.43h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V779.43z M270.55,768.65
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V768.65z"
                />
                <path
                  className={S.Text}
                  d="M295.49,779.43h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V779.43z M292.63,768.65
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V768.65z"
                />
              </g>
            </g>
            <g
              onClick={() => click(43)}
              className={spotClasses("Parks & Recreation", 43)}
              id="spot-43"
            >
              <circle cx="396.1" cy="1924.32" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M390.17,1925.64h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V1925.64z M387.3,1914.85
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V1914.85z"
                />
                <path
                  className={S.Text}
                  d="M407.08,1908.63c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4c0.91,0,1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51
			c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.93-0.95
			c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39
			s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C404.92,1908.79,405.96,1908.63,407.08,1908.63z"
                />
              </g>
            </g>
            <g
              onClick={() => click(42)}
              className={spotClasses("Parks & Recreation", 42)}
              id="spot-42"
            >
              <circle cx="1585.46" cy="1183.23" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1579.52,1184.54h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21s-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V1184.54z M1576.66,1173.76
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V1173.76z"
                />
                <path
                  className={S.Text}
                  d="M1596.09,1167.54c1.11,0,2.14,0.16,3.1,0.49c0.96,0.33,1.79,0.81,2.49,1.43c0.7,0.63,1.25,1.39,1.65,2.29
			c0.4,0.9,0.6,1.92,0.6,3.07c0,0.97-0.15,1.87-0.44,2.7s-0.69,1.62-1.19,2.38c-0.5,0.76-1.07,1.5-1.72,2.21
			c-0.65,0.71-1.34,1.44-2.07,2.18l-6.88,7.04c0.49-0.13,0.98-0.24,1.48-0.32c0.5-0.08,0.98-0.12,1.44-0.12h8.76
			c0.35,0,0.63,0.1,0.84,0.31s0.31,0.47,0.31,0.8v1.97h-17.44v-1.11c0-0.23,0.05-0.47,0.14-0.71c0.09-0.24,0.24-0.47,0.45-0.67
			l8.38-8.41c0.69-0.71,1.33-1.38,1.9-2.03c0.57-0.65,1.06-1.3,1.47-1.96c0.41-0.66,0.72-1.32,0.94-2c0.22-0.67,0.33-1.4,0.33-2.16
			s-0.12-1.44-0.36-2.02c-0.24-0.58-0.58-1.05-1-1.43s-0.92-0.66-1.5-0.85c-0.57-0.19-1.19-0.28-1.84-0.28
			c-0.66,0-1.27,0.1-1.83,0.29s-1.06,0.46-1.49,0.81c-0.43,0.35-0.8,0.76-1.09,1.23s-0.51,1-0.63,1.57
			c-0.1,0.35-0.24,0.61-0.44,0.77c-0.19,0.16-0.45,0.24-0.77,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C1593.93,1167.7,1594.97,1167.54,1596.09,1167.54z"
                />
              </g>
            </g>
            <g
              onClick={() => click(41)}
              className={spotClasses("Parks & Recreation", 41)}
              id="spot-41"
            >
              <circle cx="1139.08" cy="2181.86" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1133.15,2183.17h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.86v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21c-0.18-0.14-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V2183.17z M1130.29,2172.39
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V2172.39z"
                />
                <path
                  className={S.Text}
                  d="M1143.99,2190.12h5.62v-17.83c0-0.54,0.02-1.08,0.05-1.62l-4.67,4c-0.12,0.1-0.24,0.17-0.37,0.21
			c-0.12,0.04-0.24,0.06-0.35,0.06c-0.18,0-0.35-0.04-0.49-0.12s-0.26-0.17-0.33-0.28l-1.02-1.41l7.77-6.73h2.65v23.72h5.15v2.48
			h-14.01V2190.12z"
                />
              </g>
            </g>
            <g
              onClick={() => click(40)}
              className={spotClasses("Parks & Recreation", 40)}
              id="spot-40"
            >
              <circle cx="1732.92" cy="143.49" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1726.99,144.8h3.96v1.86c0,0.19-0.06,0.36-0.17,0.49c-0.12,0.13-0.3,0.2-0.54,0.2h-3.25v6.88h-2.87v-6.88
			h-11.59c-0.24,0-0.45-0.07-0.63-0.21s-0.29-0.31-0.34-0.52l-0.33-1.66l12.72-16.88h3.03V144.8z M1724.13,134.02
			c0-0.32,0.01-0.66,0.03-1.02c0.02-0.36,0.06-0.74,0.12-1.13l-9.51,12.94h9.36V134.02z"
                />
                <path
                  className={S.Text}
                  d="M1752.67,141.17c0,2.29-0.25,4.27-0.74,5.94c-0.49,1.67-1.17,3.06-2.02,4.15s-1.86,1.91-3.02,2.45
			s-2.41,0.8-3.73,0.8c-1.34,0-2.58-0.27-3.73-0.8c-1.15-0.54-2.15-1.35-3-2.45s-1.52-2.48-2.01-4.15
			c-0.49-1.67-0.73-3.65-0.73-5.94c0-2.29,0.24-4.27,0.73-5.95c0.49-1.68,1.16-3.07,2.01-4.17c0.85-1.1,1.85-1.92,3-2.46
			c1.15-0.54,2.39-0.8,3.73-0.8c1.33,0,2.57,0.27,3.73,0.8c1.16,0.54,2.17,1.35,3.02,2.46c0.85,1.1,1.52,2.49,2.02,4.17
			C1752.42,136.9,1752.67,138.89,1752.67,141.17z M1749.29,141.17c0-1.99-0.17-3.67-0.5-5.03c-0.33-1.36-0.79-2.45-1.35-3.28
			s-1.22-1.42-1.96-1.78s-1.51-0.54-2.32-0.54c-0.8,0-1.58,0.18-2.32,0.54s-1.39,0.95-1.95,1.78s-1.01,1.92-1.34,3.28
			c-0.33,1.36-0.5,3.03-0.5,5.03c0,2,0.17,3.67,0.5,5.02c0.33,1.35,0.78,2.44,1.34,3.27c0.56,0.83,1.21,1.42,1.95,1.78
			c0.74,0.36,1.51,0.54,2.32,0.54c0.8,0,1.58-0.18,2.32-0.54c0.74-0.36,1.4-0.95,1.96-1.78c0.57-0.83,1.02-1.92,1.35-3.27
			C1749.12,144.84,1749.29,143.17,1749.29,141.17z"
                />
              </g>
            </g>
            <g
              onClick={() => click(39)}
              className={spotClasses("Parks & Recreation", 39)}
              id="spot-39"
            >
              <circle cx="3052.21" cy="593.67" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M3041.11,577.97c1.11,0,2.13,0.16,3.07,0.47s1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			c-0.99-0.35-1.82-0.82-2.5-1.43s-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.25-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2s0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4s1.71-0.15,2.4-0.45
			c0.69-0.3,1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93
			c-0.19-0.58-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35
			c0.79-0.23,1.43-0.55,1.93-0.95c0.5-0.4,0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97
			c-0.24-0.56-0.56-1.02-0.98-1.39s-0.9-0.64-1.47-0.82s-1.18-0.27-1.83-0.27s-1.27,0.1-1.83,0.29c-0.56,0.19-1.05,0.46-1.49,0.81
			c-0.43,0.35-0.79,0.76-1.09,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24
			c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31
			c0.75-0.62,1.61-1.09,2.57-1.41C3038.95,578.13,3039.99,577.97,3041.11,577.97z"
                />
                <path
                  className={S.Text}
                  d="M3061.9,593.68c-0.99,0-1.92-0.16-2.8-0.49c-0.88-0.33-1.66-0.81-2.33-1.45c-0.67-0.64-1.2-1.42-1.6-2.35
			c-0.39-0.93-0.59-2-0.59-3.2c0-1.14,0.21-2.21,0.64-3.21c0.43-1,1.02-1.87,1.79-2.61c0.77-0.74,1.68-1.33,2.74-1.75
			c1.06-0.43,2.22-0.64,3.48-0.64c1.25,0,2.39,0.21,3.41,0.62c1.02,0.41,1.9,0.99,2.63,1.73s1.29,1.63,1.69,2.66
			c0.39,1.03,0.59,2.18,0.59,3.43c0,0.75-0.07,1.47-0.21,2.14c-0.14,0.67-0.34,1.33-0.61,1.98c-0.27,0.65-0.59,1.29-0.96,1.93
			c-0.37,0.64-0.79,1.3-1.27,1.98l-6.37,9.2c-0.16,0.23-0.38,0.42-0.67,0.56c-0.29,0.14-0.63,0.21-1,0.21h-2.99l7.96-10.42
			c0.27-0.35,0.52-0.69,0.75-1c0.23-0.32,0.45-0.63,0.66-0.95c-0.67,0.54-1.42,0.94-2.26,1.22
			C3063.72,593.54,3062.83,593.68,3061.9,593.68z M3068.43,586.03c0-0.81-0.13-1.55-0.39-2.22s-0.62-1.23-1.09-1.69
			c-0.46-0.46-1.01-0.82-1.65-1.07c-0.64-0.25-1.34-0.37-2.11-0.37c-0.8,0-1.54,0.13-2.2,0.39s-1.23,0.62-1.7,1.09
			c-0.47,0.46-0.83,1.02-1.09,1.66c-0.26,0.65-0.38,1.35-0.38,2.12c0,0.83,0.12,1.57,0.36,2.22c0.24,0.65,0.58,1.2,1.02,1.65
			c0.44,0.45,0.99,0.79,1.62,1.02c0.64,0.23,1.35,0.35,2.14,0.35c0.88,0,1.65-0.14,2.33-0.43c0.67-0.29,1.24-0.67,1.71-1.14
			c0.46-0.47,0.81-1.02,1.06-1.64C3068.31,587.35,3068.43,586.7,3068.43,586.03z"
                />
              </g>
            </g>
            <g
              onClick={() => click(38)}
              className={spotClasses("Parks & Recreation", 38)}
              id="spot-38"
            >
              <circle cx="2574.33" cy="526.83" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2563.24,512.14c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.25-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4
			c0.91,0,1.71-0.15,2.4-0.45c0.69-0.3,1.26-0.68,1.71-1.16c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73
			c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1s-1.99-0.37-3.35-0.37v-2.37
			c1.11,0,2.05-0.12,2.84-0.35s1.43-0.55,1.92-0.95s0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97
			s-0.56-1.02-0.98-1.39c-0.41-0.37-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27c-0.66,0-1.27,0.1-1.83,0.29
			s-1.06,0.46-1.49,0.81c-0.43,0.35-0.79,0.76-1.08,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76
			c-0.19,0.16-0.44,0.24-0.75,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C2561.07,512.3,2562.12,512.14,2563.24,512.14z"
                />
                <path
                  className={S.Text}
                  d="M2584.57,538.87c-1.3,0-2.49-0.17-3.58-0.52s-2.01-0.84-2.79-1.49c-0.78-0.65-1.38-1.43-1.81-2.34
			c-0.43-0.92-0.64-1.95-0.64-3.09c0-1.68,0.44-3.06,1.31-4.15s2.13-1.86,3.76-2.33c-1.38-0.51-2.41-1.27-3.11-2.29
			s-1.05-2.23-1.05-3.64c0-0.96,0.19-1.86,0.57-2.7c0.38-0.84,0.91-1.57,1.61-2.19s1.52-1.11,2.49-1.46
			c0.97-0.35,2.05-0.53,3.24-0.53c1.18,0,2.26,0.18,3.23,0.53c0.97,0.35,1.81,0.84,2.5,1.46s1.23,1.35,1.61,2.19
			c0.38,0.84,0.57,1.74,0.57,2.7c0,1.41-0.35,2.62-1.06,3.64c-0.71,1.02-1.74,1.78-3.1,2.29c1.63,0.46,2.88,1.24,3.76,2.33
			s1.31,2.47,1.31,4.15c0,1.14-0.22,2.17-0.65,3.09c-0.43,0.92-1.03,1.7-1.81,2.34c-0.77,0.64-1.7,1.14-2.78,1.49
			C2587.06,538.7,2585.87,538.87,2584.57,538.87z M2584.57,536.28c0.85,0,1.62-0.12,2.29-0.36c0.68-0.24,1.25-0.57,1.72-1
			c0.47-0.43,0.83-0.95,1.08-1.55c0.25-0.6,0.37-1.27,0.37-2c0-0.9-0.15-1.67-0.45-2.31c-0.3-0.64-0.7-1.16-1.21-1.56
			s-1.08-0.7-1.74-0.88s-1.34-0.28-2.06-0.28s-1.4,0.09-2.06,0.28s-1.24,0.48-1.74,0.88c-0.5,0.4-0.91,0.92-1.21,1.56
			s-0.45,1.41-0.45,2.31c0,0.73,0.12,1.4,0.37,2c0.25,0.6,0.61,1.12,1.08,1.55c0.47,0.43,1.04,0.77,1.71,1
			C2582.95,536.16,2583.71,536.28,2584.57,536.28z M2584.57,523.72c0.85,0,1.58-0.13,2.18-0.39c0.6-0.26,1.09-0.61,1.47-1.04
			c0.38-0.43,0.65-0.93,0.82-1.49c0.17-0.56,0.26-1.14,0.26-1.73c0-0.61-0.1-1.18-0.3-1.72c-0.2-0.54-0.5-1-0.89-1.41
			s-0.89-0.72-1.48-0.95c-0.59-0.23-1.28-0.35-2.05-0.35s-1.46,0.12-2.05,0.35s-1.08,0.55-1.48,0.95s-0.69,0.87-0.89,1.41
			c-0.2,0.54-0.3,1.11-0.3,1.72c0,0.6,0.08,1.17,0.26,1.73c0.17,0.56,0.44,1.05,0.82,1.49c0.38,0.43,0.87,0.78,1.47,1.04
			C2582.99,523.59,2583.71,523.72,2584.57,523.72z"
                />
              </g>
            </g>
            <g
              onClick={() => click(37)}
              className={spotClasses("Parks & Recreation", 37)}
              id="spot-37"
            >
              <circle cx="2364.16" cy="1244.01" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2353.07,1229.31c1.11,0,2.13,0.16,3.06,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			s0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.39,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26s1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.26-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15c0.03,0.05,0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99c0.62,0.27,1.38,0.4,2.29,0.4
			c0.91,0,1.71-0.15,2.4-0.45s1.26-0.68,1.72-1.16c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73
			c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1s-1.99-0.37-3.35-0.37v-2.37
			c1.11,0,2.05-0.12,2.84-0.35c0.78-0.23,1.43-0.55,1.92-0.95s0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86
			c0-0.75-0.12-1.41-0.36-1.97s-0.56-1.02-0.98-1.39c-0.41-0.37-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27
			c-0.66,0-1.27,0.1-1.82,0.29c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.79,0.76-1.08,1.24c-0.29,0.48-0.51,1-0.66,1.56
			c-0.1,0.35-0.24,0.61-0.44,0.76c-0.19,0.16-0.44,0.24-0.75,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29
			c0.17-1.19,0.5-2.25,0.99-3.17c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41
			C2350.9,1229.47,2351.95,1229.31,2353.07,1229.31z"
                />
                <path
                  className={S.Text}
                  d="M2383.61,1229.6v1.46c0,0.41-0.04,0.75-0.14,1.02c-0.09,0.27-0.19,0.49-0.28,0.68l-10.82,21.84
			c-0.16,0.32-0.38,0.59-0.66,0.81c-0.28,0.23-0.66,0.34-1.13,0.34h-2.32l10.98-21.57c0.16-0.3,0.32-0.58,0.48-0.84
			c0.16-0.26,0.35-0.5,0.56-0.73h-13.65c-0.21,0-0.39-0.08-0.56-0.25c-0.16-0.16-0.25-0.35-0.25-0.56v-2.21H2383.61z"
                />
              </g>
            </g>
            <g
              onClick={() => click(36)}
              className={spotClasses("Parks & Recreation", 36)}
              id="spot-36"
            >
              <circle cx="1707.52" cy="1164.94" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M1696.43,1150.25c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.67,0.58,1.2,1.29,1.58,2.12
			s0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26s1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			c-0.98-0.35-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58
			c0.26-0.11,0.51-0.16,0.77-0.16c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15
			s0.05,0.11,0.07,0.17c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25s0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4
			c0.91,0,1.71-0.15,2.4-0.45s1.26-0.68,1.71-1.16c0.46-0.47,0.8-1,1.03-1.59s0.35-1.16,0.35-1.73c0-0.71-0.09-1.35-0.28-1.93
			c-0.19-0.58-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1c-0.87-0.24-1.99-0.37-3.35-0.37v-2.37c1.11,0,2.05-0.12,2.84-0.35
			s1.43-0.55,1.92-0.95c0.5-0.4,0.86-0.88,1.09-1.44s0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97
			c-0.24-0.56-0.56-1.02-0.98-1.39s-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27s-1.27,0.1-1.82,0.29
			c-0.56,0.19-1.06,0.46-1.49,0.81c-0.43,0.35-0.79,0.76-1.08,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76
			c-0.2,0.16-0.44,0.24-0.75,0.24c-0.06,0-0.12,0-0.19-0.01c-0.07-0.01-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C1694.27,1150.41,1695.31,1150.25,1696.43,1150.25z"
                />
                <path
                  className={S.Text}
                  d="M1719.06,1160.66c1.05,0,2.04,0.17,2.97,0.52c0.94,0.35,1.76,0.85,2.46,1.51c0.71,0.66,1.27,1.48,1.68,2.45
			c0.41,0.97,0.62,2.07,0.62,3.31c0,1.2-0.22,2.32-0.66,3.36s-1.05,1.93-1.83,2.7c-0.78,0.77-1.73,1.37-2.84,1.81
			c-1.11,0.44-2.32,0.66-3.65,0.66c-1.31,0-2.5-0.21-3.57-0.63c-1.07-0.42-1.97-1.02-2.73-1.79c-0.75-0.77-1.34-1.71-1.74-2.81
			c-0.41-1.1-0.61-2.33-0.61-3.7c0-1.14,0.25-2.36,0.77-3.65c0.51-1.29,1.32-2.68,2.43-4.16l6.62-8.92c0.17-0.22,0.41-0.4,0.71-0.56
			c0.3-0.15,0.65-0.23,1.04-0.23h2.88l-9.07,11.48c0.62-0.43,1.31-0.76,2.06-0.99C1717.37,1160.78,1718.18,1160.66,1719.06,1160.66z
			 M1712.31,1168.62c0,0.84,0.12,1.61,0.36,2.3c0.24,0.69,0.6,1.29,1.07,1.79c0.47,0.5,1.04,0.88,1.71,1.16s1.44,0.41,2.31,0.41
			c0.88,0,1.67-0.14,2.37-0.42c0.71-0.28,1.31-0.67,1.82-1.17c0.5-0.5,0.89-1.09,1.17-1.77c0.27-0.68,0.41-1.42,0.41-2.23
			c0-0.85-0.13-1.62-0.4-2.31c-0.27-0.69-0.65-1.27-1.13-1.75c-0.49-0.48-1.07-0.85-1.75-1.1c-0.68-0.26-1.43-0.38-2.25-0.38
			c-0.88,0-1.67,0.15-2.37,0.45c-0.71,0.3-1.3,0.7-1.8,1.21s-0.87,1.09-1.13,1.75C1712.44,1167.21,1712.31,1167.9,1712.31,1168.62z"
                />
              </g>
            </g>
            <g
              onClick={() => click(35)}
              className={spotClasses("Parks & Recreation", 35)}
              id="spot-35"
            >
              <circle cx="2164.02" cy="740.22" r="29.9" />
              <g>
                <path
                  className={S.Text}
                  d="M2152.93,725.52c1.11,0,2.13,0.16,3.07,0.47c0.94,0.32,1.74,0.77,2.42,1.35c0.68,0.58,1.2,1.29,1.58,2.12
			c0.38,0.83,0.57,1.75,0.57,2.76c0,0.83-0.11,1.57-0.32,2.22s-0.52,1.22-0.91,1.71c-0.4,0.49-0.87,0.91-1.43,1.25
			c-0.56,0.34-1.19,0.61-1.88,0.82c1.7,0.45,2.98,1.2,3.84,2.26c0.86,1.06,1.29,2.38,1.29,3.98c0,1.21-0.23,2.29-0.68,3.25
			c-0.46,0.96-1.08,1.78-1.87,2.46c-0.79,0.67-1.71,1.19-2.76,1.55c-1.05,0.36-2.18,0.54-3.38,0.54c-1.39,0-2.57-0.17-3.56-0.52
			s-1.82-0.82-2.5-1.43c-0.68-0.61-1.24-1.33-1.68-2.16c-0.44-0.83-0.81-1.74-1.11-2.71l1.39-0.58c0.25-0.11,0.51-0.16,0.77-0.16
			c0.24,0,0.46,0.05,0.65,0.15c0.19,0.1,0.33,0.26,0.43,0.48c0.02,0.05,0.05,0.1,0.07,0.15s0.05,0.11,0.07,0.17
			c0.17,0.35,0.38,0.75,0.62,1.2c0.24,0.44,0.57,0.86,0.99,1.25c0.41,0.39,0.93,0.72,1.54,0.99s1.38,0.4,2.29,0.4
			c0.91,0,1.71-0.15,2.4-0.45c0.69-0.3,1.26-0.68,1.71-1.16c0.46-0.47,0.8-1,1.03-1.59c0.23-0.58,0.35-1.16,0.35-1.73
			c0-0.71-0.09-1.35-0.28-1.93s-0.53-1.09-1.02-1.51c-0.49-0.43-1.17-0.76-2.04-1s-1.99-0.37-3.35-0.37v-2.37
			c1.11,0,2.05-0.12,2.84-0.35s1.43-0.55,1.92-0.95s0.86-0.88,1.09-1.44c0.22-0.56,0.34-1.18,0.34-1.86c0-0.75-0.12-1.41-0.36-1.97
			s-0.56-1.02-0.98-1.39c-0.41-0.37-0.9-0.64-1.47-0.82c-0.57-0.18-1.18-0.27-1.83-0.27c-0.66,0-1.27,0.1-1.83,0.29
			s-1.06,0.46-1.49,0.81c-0.43,0.35-0.79,0.76-1.08,1.24c-0.29,0.48-0.51,1-0.66,1.56c-0.1,0.35-0.24,0.61-0.44,0.76
			c-0.19,0.16-0.44,0.24-0.75,0.24c-0.06,0-0.12,0-0.19-0.01s-0.14-0.01-0.23-0.03l-1.7-0.29c0.17-1.19,0.5-2.25,0.99-3.17
			c0.49-0.92,1.1-1.69,1.85-2.31c0.75-0.62,1.61-1.09,2.57-1.41C2150.76,725.68,2151.81,725.52,2152.93,725.52z"
                />
                <path
                  className={S.Text}
                  d="M2181.54,727.24c0,0.46-0.15,0.84-0.44,1.14c-0.29,0.3-0.78,0.45-1.48,0.45h-8.21l-1.21,6.86
			c1.36-0.29,2.62-0.44,3.78-0.44c1.36,0,2.56,0.2,3.6,0.6c1.04,0.4,1.91,0.96,2.62,1.66c0.71,0.71,1.24,1.54,1.6,2.5
			c0.36,0.96,0.54,2.01,0.54,3.14c0,1.39-0.24,2.64-0.73,3.76c-0.49,1.12-1.15,2.08-2,2.87c-0.85,0.8-1.84,1.41-2.98,1.83
			c-1.14,0.43-2.38,0.64-3.71,0.64c-0.77,0-1.5-0.08-2.21-0.23c-0.71-0.15-1.36-0.36-1.97-0.61c-0.61-0.25-1.17-0.55-1.7-0.88
			c-0.52-0.33-0.99-0.68-1.39-1.04l0.99-1.39c0.22-0.32,0.51-0.47,0.88-0.47c0.23,0,0.5,0.09,0.81,0.28
			c0.31,0.19,0.68,0.4,1.12,0.63c0.44,0.23,0.95,0.44,1.54,0.63s1.29,0.28,2.11,0.28c0.91,0,1.73-0.15,2.46-0.44
			c0.73-0.29,1.35-0.71,1.87-1.25c0.52-0.54,0.92-1.19,1.2-1.94c0.28-0.75,0.42-1.6,0.42-2.54c0-0.82-0.12-1.55-0.36-2.21
			c-0.24-0.66-0.6-1.22-1.08-1.68c-0.48-0.46-1.08-0.82-1.8-1.08c-0.72-0.25-1.56-0.38-2.52-0.38c-0.66,0-1.34,0.05-2.04,0.16
			c-0.71,0.11-1.44,0.29-2.19,0.53l-2.04-0.6l2.12-12.23h12.39V727.24z"
                />
              </g>
            </g>
          </motion.g>
        )}
      </AnimatePresence>
    </g>
  );
};

export default Spots;
