import React, { useEffect } from "react";
import classNames from "classnames";
import * as S from "./SlideInspired.module.sass";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { Power1 } from "gsap";

type Props = {
  className: string;
  ms: gsap.core.Tween | undefined;
};

const SlideInspired = ({ className, ms }: Props) => {
  useEffect(() => {
    gsap.set("#shadow-3", {
      // @ts-ignore
      width: document.querySelector("#box-3").offsetHeight * 0.6666,
      // @ts-ignore
      height: document.querySelector("#box-3").offsetHeight,
      right: `-5%`,
      yPercent: 0,
    });

    if (window.innerWidth < 992 || window.innerWidth < window.innerHeight) {
      /**
       * Slide Inspired
       * Leave animation
       */
      const inspiredLeaveTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".leave-inspired",
          start: "center 75%",
          end: "center 25%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      inspiredLeaveTimeline.fromTo(
        ".leave-inspired",
        {
          x: 0,
          y: -100,
          scale: 0.8,
          opacity: 0,
          rotate: -60,
        },
        {
          x: 100,
          y: 0,
          opacity: 0.2,
          rotate: 0,
        }
      );

      /**
       * Slide Inspired
       * Shadow 3
       */
      const inspiredShadowTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: "#shadow-3",
          start: "top 100%",
          end: "bottom 50%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      inspiredShadowTimeline.fromTo(
        "#shadow-3",
        {
          clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
        },
        {
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        }
      );

      /**
       * Slide Inspired
       * Image Over
       */
      const inpiredImageOverTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".slide-3-image-cover",
          start: "top 110%",
          end: "bottom 60%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      inpiredImageOverTimeline.fromTo(
        ".slide-3-image-cover",
        {
          clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
        },
        {
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (ms !== undefined) {
      if (window.innerWidth >= 992 || window.innerWidth > window.innerHeight) {
        /**
         * Slide Rooftop
         * Leave animation
         */
        gsap.from(".leave-inspired", {
          x: 100,
          y: -100,
          opacity: 0,
          duration: 1,
          rotate: -60,
          ease: Power1.easeOut,
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: ".leave-inspired",
            start: "left 75%",
            end: "right 50%",
            toggleActions: "play none none none",
          },
        });

        /**
         * Slide Inspired
         * Shadow 3
         */
        gsap.fromTo(
          "#shadow-3",
          {
            clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
          },
          {
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
            scrollTrigger: {
              containerAnimation: ms,
              horizontal: true,
              trigger: "#shadow-3",
              start: "left 75%",
              end: "right 55%",
              toggleActions: "play none none none",
              scrub: true,
            },
          }
        );

        /**
         * Slide 3
         * Image Over
         */
        gsap.fromTo(
          ".slide-3-image-cover",
          {
            clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
          },
          {
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
            scrollTrigger: {
              containerAnimation: ms,
              horizontal: true,
              trigger: ".slide-3-image-cover",
              start: "left 90%",
              end: "right 40%",
              toggleActions: "play none none none",
              scrub: true,
            },
          }
        );
      }
    }
  }, [ms]);
  return (
    <div className={classNames([className, S.SlideInspired])}>
      <div className={S.Grid}>
        <div className={S.ImageContainer}>
          <div id={`box-3`} className={S.Box}>
            <div id={`shadow-3`} className={S.Shadow} />
            <div className={S.Image}>
              <StaticImage
                className={S.Img}
                src="../../../../images/image-living-rendering.jpg"
                alt="Relax on the rooftop"
                objectFit="cover"
                placeholder="none"
                loading={`eager`}
                imgStyle={{ transform: `scale(1.125)` }}
              />
              <StaticImage
                className={classNames([`slide-3-image-cover`, S.ImgOver])}
                src="../../../../images/image-living-rendering.jpg"
                alt="Relax on the rooftop"
                objectFit="cover"
                placeholder="none"
                loading={`eager`}
              />
            </div>
          </div>
        </div>
        <main className={S.Main}>
          <h1>
            <div className={classNames(["leave-inspired", S.Leave])} />
            Wake up inspired
          </h1>
          <p>Greet each day with soothing views of hills and vineyards.</p>
        </main>
      </div>
    </div>
  );
};

export default SlideInspired;
