// extracted by mini-css-extract-plugin
export var Box = "SlideStyle-module--Box--DNEru";
export var Grid = "SlideStyle-module--Grid--4JZg5";
export var Image = "SlideStyle-module--Image--+sB45";
export var ImageContainer = "SlideStyle-module--ImageContainer--+klbv";
export var Img = "SlideStyle-module--Img--7fqnR";
export var ImgOver = "SlideStyle-module--ImgOver--N2-9s";
export var Leave = "SlideStyle-module--Leave--cxJEq";
export var Main = "SlideStyle-module--Main--T8Zib";
export var Shadow = "SlideStyle-module--Shadow--aWJvw";
export var SlideStyle = "SlideStyle-module--SlideStyle--Zfntr";