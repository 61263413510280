// extracted by mini-css-extract-plugin
export var Arrow = "SlideRooftop-module--Arrow--Z3pil";
export var Bottom = "SlideRooftop-module--Bottom--Qty8S";
export var BottomContainer = "SlideRooftop-module--BottomContainer--Jcwpe";
export var Box = "SlideRooftop-module--Box--evEL0";
export var Grid = "SlideRooftop-module--Grid--hud4f";
export var Image = "SlideRooftop-module--Image--6ZAWe";
export var ImageContainer = "SlideRooftop-module--ImageContainer--35i0M";
export var Img = "SlideRooftop-module--Img--5qTNU";
export var ImgOver = "SlideRooftop-module--ImgOver--nFP2v";
export var Leave = "SlideRooftop-module--Leave--pwMFM";
export var Main = "SlideRooftop-module--Main--1lA+7";
export var Shadow = "SlideRooftop-module--Shadow--Q5tLd";
export var SlideRooftop = "SlideRooftop-module--SlideRooftop--TJG13";
export var Top = "SlideRooftop-module--Top--2xSQ7";