import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as S from "./SVG.module.sass";
import { useSelector } from "react-redux";
import { selectSpot } from "../../../../redux/dataSlice";

const animation = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
      easing: "linear",
    },
  },
  exit: {
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.2,
      easing: "linear",
    },
  },
};

const Tooltips = () => {
  const spot = useSelector(selectSpot);

  return (
    <g id="POPS" className={S.Tooltips}>
      <AnimatePresence>
        {spot === 1 && (
          <motion.g
            id="_x31_-Nesters_Market"
            transform={`matrix(1, 0, 0, 1, -2, -5)`}
            key="spot-1"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2364.791,774.441h-531.057c-14.863,0-26.911-12.049-26.911-26.911v-53.822
				c0-14.863,12.049-26.911,26.911-26.911h531.057c14.863,0,26.911,12.049,26.911,26.911v53.822
				C2391.702,762.393,2379.654,774.441,2364.791,774.441z"
              />
              <polygon
                className={S.Black}
                points="2099.263,818.009 2131.314,762.495 2067.212,762.495 			"
              />
            </g>
            <text
              transform="matrix(1 0 0 1 1866.7274 738.8809)"
              className={S.White}
            >
              Nesters Market
            </text>
          </motion.g>
        )}

        {spot === 2 && (
          <motion.g
            id="_x32_-Nature_x2019_s_Fare_Markets__x2013__West_Kelowna"
            key="spot-2"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M923.883,2112.078H230.79c-14.863,0-26.911-12.049-26.911-26.911V1951.7
				c0-14.863,12.049-26.911,26.911-26.911h693.093c14.863,0,26.911,12.048,26.911,26.911v133.467
				C950.795,2100.03,938.746,2112.078,923.883,2112.078z"
              />
              <polygon
                className={S.Black}
                points="289.111,1886.086 257.06,1941.6 321.162,1941.6 			"
              />
            </g>
            <g>
              <text transform="matrix(1 0 0 1 247.02 2008.33)">
                <tspan x="0" y="0" className={S.White}>
                  Nature’s Fare Markets
                </tspan>
                <tspan x="134.522" y="68" className={S.White}>
                  – West Kelowna
                </tspan>
              </text>
            </g>
          </motion.g>
        )}

        {spot === 3 && (
          <motion.g
            id="_x33_-Walmart_Supercentre_"
            transform={`matrix(1, 0, 0, 1, 1, -4)`}
            key="spot-3"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M803.538,1652.829H60.819c-14.863,0-26.911-12.049-26.911-26.911v-53.822
				c0-14.863,12.049-26.911,26.911-26.911h742.719c14.863,0,26.911,12.048,26.911,26.911v53.822
				C830.449,1640.78,818.401,1652.829,803.538,1652.829z"
              />
              <polygon
                className={S.Black}
                points="161.551,1696.397 193.602,1640.883 129.5,1640.883 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 113.8488 1617.2679)"
                className={S.White}
              >
                Walmart Supercentre
              </text>
            </g>
          </motion.g>
        )}

        {spot === 4 && (
          <motion.g
            id="_x34_-Real_Canadian_Superstore"
            transform={`matrix(1, 0, 0, 1, 9, -1)`}
            key="spot-4"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M1022.663,1868.677H159.768c-14.863,0-26.911-12.049-26.911-26.911v-53.822
				c0-14.863,12.049-26.911,26.911-26.911h862.896c14.863,0,26.911,12.048,26.911,26.911v53.822
				C1049.574,1856.628,1037.526,1868.677,1022.663,1868.677z"
              />
              <polygon
                className={S.Black}
                points="105.099,1811.178 160.613,1843.229 160.613,1779.127 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 206.2837 1833.1163)"
                className={S.White}
              >
                Real Canadian Superstore
              </text>
            </g>
          </motion.g>
        )}

        {spot === 5 && (
          <motion.g
            id="_x35_-Mediterranean_Market"
            transform={`matrix(1, 0, 0, 1, 1, -4)`}
            key="spot-5"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2535.69,287.224h762.863c14.863,0,26.911,12.048,26.911,26.911v53.822
				c0,14.863-12.049,26.911-26.911,26.911H2535.69c-14.863,0-26.911-12.049-26.911-26.911v-53.822
				C2508.779,299.273,2520.828,287.224,2535.69,287.224z"
              />
              <polygon
                className={S.Black}
                points="3226.284,243.656 3194.233,299.17 3258.335,299.17 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 2581.0522 358.5401)"
                className={S.White}
              >
                Mediterranean Market
              </text>
            </g>
          </motion.g>
        )}

        {spot === 6 && (
          <motion.g
            id="_x36_-The_Modest_Butcher_Kitchen__x2B__Market_xA0_"
            key="spot-6"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2527.818,939.206h-702.244c-14.863,0-26.911-12.049-26.911-26.911V742.424
				c0-14.863,12.048-26.911,26.911-26.911h702.244c14.863,0,26.911,12.049,26.911,26.911v169.871
				C2554.729,927.157,2542.681,939.206,2527.818,939.206z"
              />
              <polygon
                className={S.Black}
                points="2176.696,982.774 2208.747,927.26 2144.645,927.26 			"
              />
            </g>
            <g>
              <text transform="matrix(1 0 0 1 1867.374 805.32)">
                <tspan x="0" y="0" className={S.White}>
                  The Modest Butcher
                </tspan>
                <tspan x="38.484" y="86" className={S.White}>
                  Kitchen + Market
                </tspan>
              </text>
            </g>
          </motion.g>
        )}

        {spot === 7 && (
          <motion.g
            id="_x37_-Blu_Saffron_Bistro"
            transform={`matrix(1, 0, 0, 1, 1, 0)`}
            key="spot-7"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2348.46,936.919h-604.69c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h604.69c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2379.022,923.236,2365.339,936.919,2348.46,936.919z"
              />
              <polygon
                className={S.Black}
                points="2046.115,980.487 2078.166,924.973 2014.064,924.973 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1784.0961 896.7294)"
                className={S.White}
              >
                Blu Saffron Bistro
              </text>
            </g>
          </motion.g>
        )}

        {spot === 8 && (
          <motion.g
            id="_x38_-Old_Vines_Restaurant"
            transform={`matrix(1, 0, 0, 1, 0, -3)`}
            key="spot-8"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2088.957,1607.239H1360.9c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h728.057c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2119.519,1593.556,2105.836,1607.239,2088.957,1607.239z"
              />
              <polygon
                className={S.Black}
                points="1724.928,1650.807 1756.979,1595.293 1692.877,1595.293 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1405.3103 1567.0504)"
                className={S.White}
              >
                Old Vines Restaurant
              </text>
            </g>
          </motion.g>
        )}

        {spot === 9 && (
          <motion.g
            id="_x39_-West_Sakura_Teriyaki_Restaurant"
            key="spot-9"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M1621.163,649.922h-635.18c-16.879,0-30.562-13.683-30.562-30.562V467.298
				c0-16.879,13.683-30.562,30.562-30.562h635.18c16.879,0,30.562,13.683,30.562,30.562V619.36
				C1651.725,636.239,1638.042,649.922,1621.163,649.922z"
              />
              <polygon
                className={S.Black}
                points="1303.573,693.49 1335.625,637.976 1271.522,637.976 			"
              />
            </g>
            <g>
              <text transform="matrix(1 0 0 1 1114.8501 524.9946)">
                <tspan x="0" y="0" className={S.White}>
                  West Sakura
                </tspan>
                <tspan x="-100.942" y="86" className={S.White}>
                  Teriyaki Restaurant
                </tspan>
              </text>
            </g>
          </motion.g>
        )}

        {spot === 10 && (
          <motion.g
            id="_x31_0-19_Okanagan_Grill__x2B__Bar"
            transform={`matrix(1, 0, 0, 1, -3, -3)`}
            key="spot-10"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M861.909,1808.246H82.818c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h779.091c16.879,0,30.562,13.683,30.562,30.562v61.124
				C892.471,1794.563,878.788,1808.246,861.909,1808.246z"
              />
              <polygon
                className={S.Black}
                points="399.019,1851.814 431.07,1796.3 366.968,1796.3 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 123.1189 1768.0573)"
                className={S.White}
              >
                19 Okanagan Grill + Bar
              </text>
            </g>
          </motion.g>
        )}

        {spot === 11 && (
          <motion.g
            id="_x31_1-Earls_Kitchen__x2B__Bar"
            transform={`matrix(1, 0, 0, 1, 0, -3)`}
            key="spot-11"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M3288.334,360.142h-663.047c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h663.047c16.879,0,30.562,13.683,30.562,30.562v61.124
				C3318.896,346.459,3305.213,360.142,3288.334,360.142z"
              />
              <polygon
                className={S.Black}
                points="2956.81,196.432 2924.759,251.946 2988.861,251.946 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 2669.9077 319.9529)"
                className={S.White}
              >
                Earls Kitchen + Bar
              </text>
            </g>
          </motion.g>
        )}

        {spot === 12 && (
          <motion.g
            id="_x31_2-West_Coast_Grill__x26__Oyster_Bar_Kelowna"
            transform={`matrix(1, 0, 0, 1, 2, -3)`}
            key="spot-12"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2842.992,335.347h-696.133c-16.879,0-30.562-13.683-30.562-30.562V152.723
				c0-16.879,13.683-30.562,30.562-30.562h696.133c16.879,0,30.562,13.683,30.562,30.562v152.062
				C2873.554,321.664,2859.871,335.347,2842.992,335.347z"
              />
              <polygon
                className={S.Black}
                points="2923.936,234.971 2868.422,202.92 2868.422,267.022 			"
              />
            </g>
            <g>
              <text transform="matrix(1 0 0 1 2215.4111 210.4203)">
                <tspan x="0" y="0" className={S.White}>
                  West Coast Grill &amp;
                </tspan>
                <tspan x="-20.592" y="86" className={S.White}>
                  Oyster Bar Kelowna
                </tspan>
              </text>
            </g>
          </motion.g>
        )}

        {spot === 13 && (
          <motion.g
            id="_x31_3-Krafty_Kitchen__x2B__Bar"
            key="spot-13"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2667.821,272.744h707.4c16.879,0,30.562,13.683,30.562,30.562v61.124c0,16.879-13.683,30.562-30.562,30.562
				h-707.4c-16.879,0-30.562-13.683-30.562-30.562v-61.124C2637.259,286.427,2650.942,272.744,2667.821,272.744z"
              />
              <polygon
                className={S.Black}
                points="3021.521,229.176 2989.469,284.69 3053.572,284.69 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 2709.9746 353.0594)"
                className={S.White}
              >
                Krafty Kitchen + Bar
              </text>
            </g>
          </motion.g>
        )}

        {spot === 14 && (
          <motion.g
            id="_x31_4-Terrace_Restaurant"
            transform={`matrix(1, 0, 0, 1, 0, -5)`}
            key="spot-14"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M1304.858,1845.275H658.124c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h646.735c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1335.42,1831.592,1321.737,1845.275,1304.858,1845.275z"
              />
              <polygon
                className={S.Black}
                points="981.491,1888.843 1013.542,1833.329 949.44,1833.329 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 695.893 1805.0853)"
                className={S.White}
              >
                Terrace Restaurant
              </text>
            </g>
          </motion.g>
        )}

        {spot === 15 && (
          <motion.g
            id="_x31_5-II_Mercato_Social_Kitchen"
            transform={`matrix(1, 0, 0, 1, 0, -5)`}
            key="spot-15"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M170.197,1835.681h845.892c16.879,0,30.562,13.683,30.562,30.562v61.124
				c0,16.879-13.683,30.562-30.562,30.562H170.197c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				C139.635,1849.364,153.318,1835.681,170.197,1835.681z"
              />
              <polygon
                className={S.Black}
                points="94.405,1896.805 149.919,1928.856 149.919,1864.754 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 222.4635 1916.0406)"
                className={S.White}
              >
                II Mercato Social Kitchen
              </text>
            </g>
          </motion.g>
        )}

        {spot === 16 && (
          <motion.g
            id="_x31_6-Lakesider_Brewing"
            key="spot-16"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2404.435,726.478h-632.575c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h632.575c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2434.996,712.795,2421.313,726.478,2404.435,726.478z"
              />
              <polygon
                className={S.Black}
                points="2088.147,770.046 2120.198,714.532 2056.096,714.532 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1817.2373 686.2882)"
                className={S.White}
              >
                Lakesider Brewing
              </text>
            </g>
          </motion.g>
        )}

        {spot === 17 && (
          <motion.g
            id="_x31_7-The_Hatching_Post_Brewery"
            key="spot-17"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2384.779,1008.307h-906.54c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h906.54c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2415.341,994.624,2401.657,1008.307,2384.779,1008.307z"
              />
              <polygon
                className={S.Black}
                points="1931.509,1051.876 1963.56,996.361 1899.457,996.361 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1519.5885 968.1182)"
                className={S.White}
              >
                The Hatching Post Brewery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 18 && (
          <motion.g
            id="_x31_8-Friends_Pub"
            key="spot-18"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2306.762,394.604h-438.801c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h438.801c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2337.324,380.921,2323.641,394.604,2306.762,394.604z"
              />
              <polygon
                className={S.Black}
                points="2087.362,438.173 2119.413,382.658 2055.311,382.658 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1912.0662 354.415)"
                className={S.White}
              >
                Friends Pub
              </text>
            </g>
          </motion.g>
        )}

        {spot === 19 && (
          <motion.g
            id="_x31_9-Neighbours_Pub"
            key="spot-19"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M744.311,1322.526H172.775c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h571.536c16.879,0,30.562,13.683,30.562,30.562v61.124
				C774.873,1308.843,761.19,1322.526,744.311,1322.526z"
              />
              <polygon
                className={S.Black}
                points="103.279,1261.402 158.793,1293.453 158.793,1229.351 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 217.5175 1282.3365)"
                className={S.White}
              >
                Neighbours Pub
              </text>
            </g>
          </motion.g>
        )}

        {spot === 20 && (
          <motion.g
            id="_x32_0-Kelly_O_x2019_Bryan_x2019_s_Restaurant_and_Pub"
            key="spot-20"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2014.195,1578.605H1361.37c-14.863,0-26.911-12.049-26.911-26.911v-169.871
				c0-14.863,12.049-26.911,26.911-26.911h652.825c14.863,0,26.911,12.049,26.911,26.911v169.871
				C2041.106,1566.556,2029.057,1578.605,2014.195,1578.605z"
              />
              <polygon
                className={S.Black}
                points="1687.782,1622.173 1719.833,1566.659 1655.731,1566.659 			"
              />
            </g>
            <g>
              <text transform="matrix(1 0 0 1 1460.8992 1444.7181)">
                <tspan x="0" y="0" className={S.White}>
                  Kelly O’Bryan’s
                </tspan>
                <tspan x="-68.472" y="86" className={S.White}>
                  Restaurant and Pub
                </tspan>
              </text>
            </g>
          </motion.g>
        )}

        {spot === 21 && (
          <motion.g
            id="_x32_1-O_x2019_Flannigan_x2019_s_Pub"
            transform={`matrix(1, 0, 0, 1, 7, 2)`}
            key="spot-21"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M3329.204,327.83h-612.009c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h612.009c16.879,0,30.562,13.683,30.562,30.562v61.124
				C3359.766,314.147,3346.083,327.83,3329.204,327.83z"
              />
              <polygon
                className={S.Black}
                points="3041.257,157.893 3009.206,213.407 3073.308,213.407 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 2747.5083 287.6416)"
                className={S.White}
              >
                O’Flannigan’s Pub
              </text>
            </g>
          </motion.g>
        )}

        {spot === 22 && (
          <motion.g
            id="_x32_2-Mt._Boucherie_Estate_Winery"
            transform={`matrix(1, 0, 0, 1, 0, -4)`}
            key="spot-22"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2664.213,985.751h-971.287c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h971.287c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2694.775,972.068,2681.092,985.751,2664.213,985.751z"
              />
              <polygon
                className={S.Black}
                points="2178.57,1029.319 2210.621,973.805 2146.518,973.805 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1744.4368 945.5617)"
                className={S.White}
              >
                Mt. Boucherie Estate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 23 && (
          <motion.g
            id="_x32_3-Volcanic_Hills_Estate_Winery"
            transform={`matrix(1, 0, 0, 1, 0, -7)`}
            key="spot-23"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2500.871,990.16h-907.943c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h907.943c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2531.433,976.477,2517.75,990.16,2500.871,990.16z"
              />
              <polygon
                className={S.Black}
                points="2046.9,1033.728 2078.951,978.214 2014.849,978.214 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1624.7198 949.9702)"
                className={S.White}
              >
                Volcanic Hills Estate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 24 && (
          <motion.g
            id="_x32_4-Beaumount_Family_Estate_Winery"
            key="spot-24"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2594.024,880.816H1511.84c-16.879,0-30.562-13.683-30.562-30.562V789.13
				c0-16.879,13.683-30.562,30.562-30.562h1082.184c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2624.586,867.133,2610.903,880.816,2594.024,880.816z"
              />
              <polygon
                className={S.Black}
                points="2052.932,924.384 2084.983,868.87 2020.881,868.87 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1552.9919 840.627)"
                className={S.White}
              >
                Beaumount Family Estate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 25 && (
          <motion.g
            id="_x32_5-Little_Straw_Winery"
            transform={`matrix(1, 0, 0, 1, 0, -2)`}
            key="spot-25"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2219.516,947.725h-654.389c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h654.389c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2250.078,934.042,2236.395,947.725,2219.516,947.725z"
              />
              <polygon
                className={S.Black}
                points="1892.322,991.293 1924.373,935.779 1860.271,935.779 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1601.5399 907.5359)"
                className={S.White}
              >
                Little Straw Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 26 && (
          <motion.g
            id="_x32_6-Grizzli_Winery"
            key="spot-26"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2207.257,531.404h-503.124c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h503.124c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2237.819,517.721,2224.135,531.404,2207.257,531.404z"
              />
              <polygon
                className={S.Black}
                points="1955.695,574.972 1987.746,519.458 1923.644,519.458 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1746.1279 491.2149)"
                className={S.White}
              >
                Grizzli Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 27 && (
          <motion.g
            id="_x32_7-The_Hatch"
            key="spot-27"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2112.941,1392.215h-416.239c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h416.239c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2143.503,1378.532,2129.82,1392.215,2112.941,1392.215z"
              />
              <polygon
                className={S.Black}
                points="1904.822,1435.783 1936.873,1380.269 1872.771,1380.269 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1747.5259 1352.025)"
                className={S.White}
              >
                The Hatch
              </text>
            </g>
          </motion.g>
        )}

        {spot === 28 && (
          <motion.g
            id="_x32_8-Indigenous_World_Winery"
            key="spot-28"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2631.359,270.757h-832.691c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h832.691c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2661.921,257.074,2648.238,270.757,2631.359,270.757z"
              />
              <polygon
                className={S.Black}
                points="2215.013,314.325 2247.064,258.811 2182.962,258.811 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1831.0988 230.5676)"
                className={S.White}
              >
                Indigenous World Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 29 && (
          <motion.g
            id="_x32_9-Quails_x2019__Gate_Winery"
            transform={`matrix(1, 0, 0, 1, 0, -3)`}
            key="spot-29"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2093.654,1546.571h-693.706c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h693.706c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2124.216,1532.888,2110.533,1546.571,2093.654,1546.571z"
              />
              <polygon
                className={S.Black}
                points="1746.801,1590.14 1778.852,1534.625 1714.75,1534.625 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1444.8947 1506.3823)"
                className={S.White}
              >
                Quails’ Gate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 30 && (
          <motion.g
            id="_x33_0-Mission_Hill_Family_Estate_Winery"
            key="spot-30"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M1541.998,1905.897H459.815c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h1082.183c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1572.56,1892.214,1558.877,1905.897,1541.998,1905.897z"
              />
              <polygon
                className={S.Black}
                points="1000.906,1949.465 1032.958,1893.951 968.855,1893.951 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 502.0106 1865.7069)"
                className={S.White}
              >
                Mission Hill Family Estate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 31 && (
          <motion.g
            id="_x33_1-Frind_Estate_Winery"
            key="spot-31"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M1686.805,2065.728H996.838c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h689.968c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1717.367,2052.045,1703.684,2065.728,1686.805,2065.728z"
              />
              <polygon
                className={S.Black}
                points="1341.821,2109.296 1373.873,2053.782 1309.77,2053.782 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1043.8755 2025.5389)"
                className={S.White}
              >
                Frind Estate Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 32 && (
          <motion.g
            id="_x33_2-Crown__x26__Thieves_Winery"
            transform={`matrix(1, 0, 0, 1, -8, 0)`}
            key="spot-32"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M921.027,2105.017H54.965c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h866.063c16.879,0,30.562,13.683,30.562,30.562v61.124
				C951.589,2091.334,937.906,2105.017,921.027,2105.017z"
              />
              <polygon
                className={S.Black}
                points="135.645,2148.586 167.696,2093.072 103.594,2093.072 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 113.2795 2064.8286)"
                className={S.White}
              >
                Crown &amp; Thieves Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 33 && (
          <motion.g
            id="_x33_3-Black_Swift_Vineyards"
            transform={`matrix(1, 0, 0, 1, -4, -2)`}
            key="spot-33"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2487.452,456.757h-700.317c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h700.317c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2518.014,443.074,2504.331,456.757,2487.452,456.757z"
              />
              <polygon
                className={S.Black}
                points="2137.293,500.325 2169.344,444.811 2105.242,444.811 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 1815.9484 416.5675)"
                className={S.White}
              >
                Black Swift Vineyards
              </text>
            </g>
          </motion.g>
        )}

        {spot === 34 && (
          <motion.g
            id="_x33_4-The_Gallery_Winery"
            key="spot-34"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M801.842,1948.055H137.566c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h664.276c16.879,0,30.562,13.683,30.562,30.562v61.124
				C832.404,1934.372,818.721,1948.055,801.842,1948.055z"
              />
              <polygon
                className={S.Black}
                points="282.454,1991.623 314.505,1936.109 250.403,1936.109 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 181.7653 1907.8658)"
                className={S.White}
              >
                The Gallery Winery
              </text>
            </g>
          </motion.g>
        )}

        {spot === 35 && (
          <motion.g
            id="_x33_5-Lakeview_Heights_Community_Centre"
            key="spot-35"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M2770.755,666.213H1557.288c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h1213.467c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2801.317,652.53,2787.634,666.213,2770.755,666.213z"
              />
              <polygon
                className={S.Black}
                points="2164.022,709.781 2196.073,654.267 2131.97,654.267 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 1604.3947 626.0236)"
                className={S.White}
              >
                Lakeview Heights Community Centre
              </text>
            </g>
          </motion.g>
        )}

        {spot === 36 && (
          <motion.g
            id="_x33_6-Eain_Lamont_Park"
            key="spot-36"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M2020.452,1094.397h-627.424c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h627.424c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2051.013,1080.714,2037.33,1094.397,2020.452,1094.397z"
              />
              <polygon
                className={S.Black}
                points="1706.74,1137.966 1738.791,1082.452 1674.689,1082.452 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 1446.809 1054.208)"
                className={S.White}
              >
                Eain Lamont Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 37 && (
          <motion.g
            id="_x33_7-Kalamoir_Regional_Park"
            key="spot-37"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M2754.721,1170.578h-782.693c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h782.693c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2785.283,1156.895,2771.6,1170.578,2754.721,1170.578z"
              />
              <polygon
                className={S.Black}
                points="2363.375,1214.146 2395.426,1158.632 2331.324,1158.632 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 2021.4387 1130.3885)"
                className={S.White}
              >
                Kalamoir Regional Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 38 && (
          <motion.g
            id="_x33_8-Casa_Rio_Waterfront_Park"
            transform={`matrix(1, 0, 0, 1, 0, -3)`}
            key="spot-38"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M3009.438,456.645H2139.22c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h870.218c16.879,0,30.562,13.683,30.562,30.562v61.124
				C3040,442.962,3026.317,456.645,3009.438,456.645z"
              />
              <polygon
                className={S.Black}
                points="2574.329,500.213 2606.38,444.699 2542.278,444.699 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 2192.8647 416.4555)"
                className={S.White}
              >
                Casa Rio Waterfront Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 39 && (
          <motion.g
            id="_x33_9-Osprey_Park"
            transform={`matrix(1, 0, 0, 1, 0, -6)`}
            key="spot-39"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M3284.803,526.594h-465.237c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h465.237c16.879,0,30.562,13.683,30.562,30.562v61.124
				C3315.365,512.911,3301.681,526.594,3284.803,526.594z"
              />
              <polygon
                className={S.Black}
                points="3052.184,570.162 3084.235,514.648 3020.133,514.648 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 2869.2935 486.4044)"
                className={S.White}
              >
                Osprey Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 40 && (
          <motion.g
            id="_x34_0-Rose_Valley_Regional_Park"
            transform={`matrix(1, 0, 0, 1, 0, -8)`}
            key="spot-40"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M1304.154,223.684h855.967c16.879,0,30.562,13.683,30.562,30.562v61.124
				c0,16.879-13.683,30.562-30.562,30.562h-855.967c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				C1273.592,237.367,1287.275,223.684,1304.154,223.684z"
              />
              <polygon
                className={S.Black}
                points="1732.138,180.115 1700.087,235.63 1764.189,235.63 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 1348.2616 310.2786)"
                className={S.White}
              >
                Rose Valley Regional Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 41 && (
          <motion.g
            id="_x34_1-Gellatly_Regional_Park_xA0_"
            transform={`matrix(1, 0, 0, 1, 0, -5)`}
            key="spot-41"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M1515.468,2115.56H762.701c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h752.767c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1546.03,2101.877,1532.347,2115.56,1515.468,2115.56z"
              />
              <polygon
                className={S.Black}
                points="1139.084,2159.128 1171.135,2103.614 1107.033,2103.614 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 804.5992 2075.3708)"
                className={S.White}
              >
                Gellatly Regional Park
              </text>
            </g>
          </motion.g>
        )}

        {spot === 42 && (
          <motion.g
            id="_x34_2-Mount_Boucherie_xA0_"
            key="spot-42"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M1906.273,1109.513h-643.206c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h643.206c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1936.835,1095.83,1923.152,1109.513,1906.273,1109.513z"
              />
              <polygon
                className={S.Black}
                points="1584.67,1153.082 1616.721,1097.567 1552.619,1097.567 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 1314.3711 1069.3241)"
                className={S.White}
              >
                Mount Boucherie
              </text>
            </g>
          </motion.g>
        )}

        {spot === 43 && (
          <motion.g
            id="_x34_3-Two_Eagles_Golf_Course"
            transform={`matrix(1, 0, 0, 1, 0, -2)`}
            key="spot-43"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M967.849,1854.194H166.884c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h800.965c16.879,0,30.562,13.683,30.562,30.562v61.124
				C998.411,1840.511,984.728,1854.194,967.849,1854.194z"
              />
              <polygon
                className={S.Black}
                points="397.883,1897.762 429.934,1842.248 365.832,1842.248 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 211.5332 1814.004)"
                className={S.White}
              >
                Two Eagles Golf Course
              </text>
            </g>
          </motion.g>
        )}

        {spot === 44 && (
          <motion.g
            id="_x34_4-Shannon_Lake_Golf_Course"
            transform={`matrix(1, 0, 0, 1, -3, 2)`}
            key="spot-44"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M993.887,701.918H100.02c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h893.867c16.879,0,30.562,13.683,30.562,30.562v61.124
				C1024.449,688.235,1010.766,701.918,993.887,701.918z"
              />
              <polygon
                className={S.Black}
                points="282.121,745.486 314.172,689.972 250.07,689.972 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 149.5398 661.7285)"
                className={S.White}
              >
                Shannon Lake Golf Course
              </text>
            </g>
          </motion.g>
        )}

        {spot === 45 && (
          <motion.g
            id="_x34_5-West_Kelowna_Soccer_Dome"
            key="spot-45"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g className="st8">
              <path
                className={S.Black}
                d="M1182.5,134.056h969.241c16.879,0,30.562,13.683,30.562,30.562v61.124c0,16.879-13.683,30.562-30.562,30.562
				H1182.5c-16.879,0-30.562-13.683-30.562-30.562v-61.124C1151.939,147.739,1165.622,134.056,1182.5,134.056z"
              />
              <polygon
                className={S.Black}
                points="1667.121,90.488 1635.07,146.002 1699.172,146.002 			"
              />
            </g>
            <g className="st8">
              <text
                transform="matrix(1 0 0 1 1238.4967 220.6502)"
                className={S.White}
              >
                West Kelowna Soccer Dome
              </text>
            </g>
          </motion.g>
        )}

        {spot === 46 && (
          <motion.g
            id="_x34_6-Pins__x26__Pints_Bowling"
            transform={`matrix(1, 0, 0, 1, -3, -6)`}
            key="spot-46"
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <g>
              <path
                className={S.Black}
                d="M2794.588,307.359h-704.675c-16.879,0-30.562-13.683-30.562-30.562v-61.124
				c0-16.879,13.683-30.562,30.562-30.562h704.675c16.879,0,30.562,13.683,30.562,30.562v61.124
				C2825.15,293.676,2811.467,307.359,2794.588,307.359z"
              />
              <polygon
                className={S.Black}
                points="2442.25,350.927 2474.302,295.413 2410.199,295.413 			"
              />
            </g>
            <g>
              <text
                transform="matrix(1 0 0 1 2135.5564 267.1697)"
                className={S.White}
              >
                Pins &amp; Pints Bowling
              </text>
            </g>
          </motion.g>
        )}
      </AnimatePresence>
    </g>
  );
};

export default Tooltips;
