import React from "react";
import * as S from "./SVG.module.sass";
import Spots from "./Spots";
import Tooltips from "./Tooltips";

const SVG = () => {
  return (
    <div className={S.SVGContainer}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 3478.86 2227.91"
      >
        <Spots />
        <Tooltips />
      </svg>
    </div>
  );
};

export default SVG;
