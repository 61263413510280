// extracted by mini-css-extract-plugin
export var Button = "Hero-module--Button--2FNbP";
export var Header = "Hero-module--Header--TdnSA";
export var HeaderInner = "Hero-module--HeaderInner--LWP2k";
export var Hero = "Hero-module--Hero--RmVPi";
export var LogoContainer = "Hero-module--LogoContainer--QOKCp";
export var Main = "Hero-module--Main--c53qA";
export var MainContainer = "Hero-module--MainContainer--O1yQj";
export var Player = "Hero-module--Player--kIGZE";
export var RegisterButton = "Hero-module--RegisterButton--rQBwt";
export var Tagline = "Hero-module--Tagline--wiazG";
export var VideoContainer = "Hero-module--VideoContainer--nqvEW";