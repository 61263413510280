import React, { useEffect } from "react";
import * as S from "./Section1.module.sass";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import gsap from "gsap";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

type Props = {
  className?: string;
  click: () => void;
};

// gsap.registerPlugin(ScrollTrigger);

const Section1 = ({ className, click }: Props) => {
  useEffect(() => {
    /**
     * Section 1
     * Shadow
     */
    const section1Shadow = gsap.timeline({
      scrollTrigger: {
        trigger: "#shadow-0",
        start: "top 85%",
        end: "bottom 65%",
        toggleActions: "play none none none",
        scrub: true,
        // markers: true,
      },
    });
    section1Shadow.fromTo(
      "#shadow-0",
      {
        clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
      },
      {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
      }
    );
    /**
     * Section 1
     * Image Over
     */
    const section1ImageOver = gsap.timeline({
      scrollTrigger: {
        trigger: ".section-1-image-cover",
        start: "top+=100px 85%",
        end: "bottom+=100px 65%",
        toggleActions: "play none none none",
        scrub: true,
      },
    });
    section1ImageOver.fromTo(
      ".section-1-image-cover",
      {
        clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
      },
      {
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
      }
    );
  }, []);
  return (
    <div className={classNames([className, S.Section1])}>
      <div className={S.Inner}>
        <main className={S.Main}>
          <h1>
            A Slice of Paradise
            <div className={classNames(["leave-1", S.Leave])} />
          </h1>
          <p>
            Come home to a quiet, charming community next door to vineyards and
            retail shops with the excitement of downtown Kelowna just 10 minutes
            away.
          </p>
          <div className={S.Button} onClick={click}>
            Register Now
          </div>
        </main>
        <div id="section-1-image" className={S.ImageContainer}>
          <div className={S.Box}>
            <div
              id={`shadow-0`}
              className={classNames(["shadow-0", S.Shadow])}
            ></div>
            <StaticImage
              className={S.Image}
              src="../../../images/lakeview-exterior-rendering.jpg"
              alt="Lakeview exterior rendering"
              placeholder="none"
              loading={`eager`}
              objectFit={`cover`}
              imgStyle={{ transform: `scale(1.125)` }}
            />
            <StaticImage
              className={classNames([`section-1-image-cover`, S.ImageOver])}
              src="../../../images/lakeview-exterior-rendering.jpg"
              alt="Lakeview exterior rendering"
              placeholder="none"
              loading={`eager`}
              objectFit={`cover`}
            />
          </div>
        </div>
      </div>
      <div className={S.Pattern}></div>
    </div>
  );
};

export default Section1;
