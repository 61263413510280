import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "@reach/router";
import gsap, { Power1 } from "gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import * as S from "./Home.module.sass";
import Header from "./Header";
import Hero from "./Hero";
import Section1 from "./Section1";
import Register from "./Register";
import Footer from "./Footer";
// @ts-ignore
import Slideshow from "./Slideshow";
import AmenityMap from "./AmenityMap";
import Gallery from "./Gallery";
import LightBox from "./Gallery/LightBox";
import { useSelector } from "react-redux";
import { selectLightBox } from "../../redux/dataSlice";

import { hideScrollBar, showScrollBar } from "../Loading";

gsap.registerPlugin(ScrollSmoother, ScrollTrigger);

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
      easing: "linear",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      easing: "linear",
    },
  },
};

const Home = () => {
  const lightBox = useSelector(selectLightBox);

  if (lightBox) {
    hideScrollBar();
  } else {
    showScrollBar();
  }

  const [scrollsmoother, updateScrollSmoother] = useState<
    globalThis.ScrollSmoother | undefined
  >();

  const location = useLocation();

  // console.log("location =", location);

  const gotoRegister = () => {
    if (scrollsmoother !== undefined) {
      const offset = scrollsmoother.offset(".section-register", "top 125px");
      scrollsmoother.scrollTo(offset, true);
    }
  };

  if (location.hash === "#register") {
    gotoRegister();
  }

  useEffect(() => {
    gsap.set("#website-root", { autoAlpha: 0 });
    /**
     * @ScrollSmoother
     * Enabled GSAP ScrollSmoother
     */
    const ss = ScrollSmoother.create({
      wrapper: "#smooth-wrapper",
      content: "#smooth-content",
      smooth: 4,
      smoothTouch: 0.1,
      effects: true,
      // normalizeScroll: true,
    });
    //
    updateScrollSmoother(ss);

    gsap.to("#website-root", { autoAlpha: 1, duration: 2 });

    /**
     * @Header
     * Display header when enter section 1
     * Hide header when onEnterBack of section 1
     */
    // gsap.set(".header", { top: `-100px` });
    gsap.set(".header", {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
    });
    gsap.to(".section-1", {
      // ease: "none",
      scrollTrigger: {
        onEnter: (self) => {
          // Show Header
          gsap.to(".header", {
            duration: 0.6,
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            // top: `0%`,
            ease: Power1.easeOut,
          });
        },
        onEnterBack: (self) => {
          // Hide Header
          gsap.to(".header", {
            duration: 0.6,
            clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
            // top: `-100%`,
            ease: Power1.easeOut,
          });
        },
        trigger: ".section-1",
        pin: true,
        scrub: true,
        start: "top top",
        end: "bottom bottom",
      },
    });

    /**
     * Section 1
     * Leave animation
     */
    if (window.innerWidth <= 992) gsap.set(".leave-1", { scale: 0.8 });

    const leaveTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: ".leave-1",
        start: "top center+=100px",
        end: "bottom bottom",
        toggleActions: "play none none none",
      },
    });
    leaveTimeLine.from(".leave-1", {
      x: -100,
      opacity: 0,
      duration: 1,
      rotate: -60,
      ease: Power1.easeOut,
    });

    /**
     * Section 1
     * Image
     */
    const section1ImageTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: "#section-1-image",
        start: "top 75%",
        end: "bottom bottom",
        toggleActions: "play none none none",
      },
    });
    section1ImageTimeLine.from("#section-1-image", {
      x: 200,
      opacity: 0,
      duration: 1,
      ease: Power1.easeOut,
    });
  }, []);

  return (
    <div id={`website-root`} className={S.Root}>
      <Header className={`header`} click={gotoRegister} />
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <div id={`sections`}>
            <main id={`sections-container`} className={S.Home}>
              {/* HERO */}
              <Hero click={gotoRegister} />

              {/* SECTION 1 */}
              <Section1 className="section-1" click={gotoRegister} />

              {/* Amenity Map */}
              <AmenityMap className={`section-amenity-map`} />

              {/* Slideshow */}
              <Slideshow />

              {/* Gallery */}
              <Gallery className={`section-gallery`} />

              {/* SECTION 3 */}
              <Register className="section-register" />

              {/* FOOTER */}
              <Footer />
            </main>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {lightBox && (
          <motion.div
            key={`lightbox`}
            exit="exit"
            initial="initial"
            animate="animate"
            variants={animation}
          >
            <LightBox />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Home;
