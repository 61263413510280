import React, { useEffect } from "react";
import * as S from "./Register.module.sass";
import classNames from "classnames";

type Props = {
  className: string;
};

const Register = ({ className }: Props) => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: "21300591",
          formId: "5f83a0a2-26ae-439b-837c-84eed4d0525c",
          target: "#hubspotForm",
        });
      }
    }
  }, []);

  return (
    <div className={classNames(["section", className, S.Register])}>
      <div className={S.Container}>
        <div className={S.Inner}>
          <h1>Register Today</h1>
          <div className={S.Subtitle}>
            Register today for priority access to the latest updates on The
            Residences at Lakeview Village and one of the first opportunities to
            purchase
          </div>
          <div
            id="hubspotForm"
            className={classNames(["hubspotForm", S.HubSpotsForm])}
          ></div>
          <hr
            style={{
              minWidth: `100%`,
              border: `0px solid white`,
              outline: `0px solid white`,
            }}
          />
          {/*<div className={S.Disclaimer}>*/}
          {/*  *By registering, you consent to Epic Real Estate Solutions Inc.*/}
          {/*  and/or KiND Development Group contacting you regarding their*/}
          {/*  developments, events, announcements, or other publications. This*/}
          {/*  information is not shared or distributed to any other parties and*/}
          {/*  you may unsubscribe at any time.*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  );
};

export default Register;
