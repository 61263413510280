// extracted by mini-css-extract-plugin
export var Box = "SlideInspired-module--Box--6Z85Z";
export var Grid = "SlideInspired-module--Grid--3-6Mf";
export var Image = "SlideInspired-module--Image--Fcwni";
export var ImageContainer = "SlideInspired-module--ImageContainer--Mwk4Z";
export var Img = "SlideInspired-module--Img--wrER4";
export var ImgOver = "SlideInspired-module--ImgOver--EqzLR";
export var Leave = "SlideInspired-module--Leave--SC3Mn";
export var Main = "SlideInspired-module--Main--wi49N";
export var Shadow = "SlideInspired-module--Shadow--zofvo";
export var SlideInspired = "SlideInspired-module--SlideInspired--8ydj4";