import React, { useEffect } from "react";
import classNames from "classnames";
import * as S from "./SlideRooftop.module.sass";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { Power1 } from "gsap";

type Props = {
  className: string;
  ms: gsap.core.Tween | undefined;
};

const SlideRooftop = ({ className, ms }: Props) => {
  // console.log("ms===>", ms);

  useEffect(() => {
    gsap.set("#shadow-1", {
      // @ts-ignore
      width: document.querySelector("#box-1").offsetHeight,
      // @ts-ignore
      height: document.querySelector("#box-1").offsetHeight,
      right: `-5%`,
      yPercent: 0,
    });

    if (window.innerWidth < 992 || window.innerWidth < window.innerHeight) {
      /**
       * Slide Rooftop
       * Leave animation
       */
      gsap.set(".leave-rooftop", { scale: 0.8 });
      gsap.fromTo(
        ".leave-rooftop",
        {
          x: 100,
          y: -100,
          opacity: 0,
          rotate: -60,
        },
        {
          x: 0,
          y: 0,
          opacity: 0.2,
          rotate: 0,
          scrollTrigger: {
            trigger: ".leave-rooftop",
            start: "center 75%",
            end: "center 25%",
            toggleActions: "play none none none",
            scrub: true,
          },
        }
      );

      /**
       * Slide Rooftop
       * Shadow 1
       */
      gsap.fromTo(
        "#shadow-1",
        {
          opacity: 0,
          clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
        },
        {
          opacity: 1,
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          scrollTrigger: {
            trigger: "#shadow-1",
            start: "top 100%",
            end: "bottom 50%",
            toggleActions: "play none none none",
            scrub: true,
          },
        }
      );

      /**
       * Slide 1
       * Image Over
       */
      gsap.fromTo(
        ".slide-1-image-cover",
        {
          clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
        },
        {
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          scrollTrigger: {
            trigger: ".slide-1-image-cover",
            start: "top 110%",
            end: "bottom 60%",
            toggleActions: "play none none none",
            scrub: true,
          },
        }
      );
    }
  }, []);

  useEffect(() => {
    if (ms !== undefined) {
      if (window.innerWidth >= 992 || window.innerWidth > window.innerHeight) {
        /**
         * Slide Rooftop
         * Leave animation
         */

        const rooftopLeaveTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: ".leave-rooftop",
            containerAnimation: ms,
            horizontal: true,
            start: "left 75%",
            end: "right 25%",
            toggleActions: "play none none none",
          },
        });

        rooftopLeaveTimeline.from(".leave-rooftop", {
          x: 100,
          y: -100,
          opacity: 0,
          duration: 1,
          rotate: -60,
          ease: Power1.easeOut,
        });

        /**
         * Slide Rooftop
         * Shadow 1
         */

        const rooftopShadowTimeline = gsap.timeline({
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: "#shadow-1",
            start: "left 30%",
            end: "right 15%",
            toggleActions: "play none none none",
            scrub: true,
          },
        });
        rooftopShadowTimeline.fromTo(
          "#shadow-1",
          {
            opacity: 0,
            clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
          },
          {
            opacity: 1,
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
            duration: 2,
          }
        );

        /**
         * Slide 1
         * Image Over
         */
        const rooftopImageOverTimeline = gsap.timeline({
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: ".slide-1-image-cover",
            start: "left 17.5%",
            end: "right 0%",
            toggleActions: "play none none none",
            scrub: true,
          },
        });
        rooftopImageOverTimeline.fromTo(
          ".slide-1-image-cover",
          {
            clipPath: `polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)`,
          },
          {
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          }
        );
      }
    }
  }, [ms]);

  return (
    <div className={classNames([className, S.SlideRooftop])}>
      <div className={S.Top}>
        <div className={S.Grid}>
          <div className={S.ImageContainer}>
            <div id={`box-1`} className={S.Box}>
              <div id={`shadow-1`} className={S.Shadow} />
              <div className={S.Image}>
                <StaticImage
                  className={S.Img}
                  src="../../../../images/image-relax-on-the-rooftop.jpg"
                  alt="Relax on the rooftop"
                  objectFit="cover"
                  placeholder="none"
                  loading={`eager`}
                  imgStyle={{ transform: `scale(1.125)` }}
                />
                <StaticImage
                  className={classNames([`slide-1-image-cover`, S.ImgOver])}
                  src="../../../../images/image-relax-on-the-rooftop.jpg"
                  alt="Relax on the rooftop"
                  objectFit="cover"
                  placeholder="none"
                  loading={`eager`}
                />
              </div>
            </div>
          </div>
          <main className={S.Main}>
            <h1>
              <div className={classNames(["leave-rooftop", S.Leave])} />
              <div className={`heading-1`}>Relax on the rooftop</div>
            </h1>
            <p>
              Gather with friends old and new in community spaces like a rooftop
              lounge and courtyard.
            </p>
          </main>
        </div>
      </div>
      <div className={S.Bottom}>
        <div className={S.BottomContainer}>
          <div className={S.Arrow}>
            <StaticImage
              src="../../../../images/scroll-arrow.svg"
              alt="arrow"
              placeholder="none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideRooftop;
