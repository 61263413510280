import React from "react";
import * as S from "./Footer.module.sass";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

const Footer = () => {
  return (
    <div className={classNames(["section", "section-footer", S.Footer])}>
      <div className={S.Bottom}>
        <div className={S.Container}>
          <div className={S.LakeviewLogo}>
            <StaticImage
              src="../../../images/lakeview-logo-white.svg"
              alt="Logo of the Lakeview"
              placeholder="none"
              style={{ maxHeight: `100px` }}
              imgStyle={{ maxHeight: `100px` }}
              objectFit="contain"
            />
          </div>
          <div className={S.Disclaimer}>
            The developer reserves the right to make any modifications or
            substitutions, without notice, should they be necessary. Any
            measurements provided are approximate only and actual final sizing
            may vary. Renderings are an artist’s conception only and actual
            final product may vary and is subject to change. This is not an
            offering for sale. Any such offering must be made by way of
            disclosure statement. E.&O.E. Sales and Marketing provided by Epic
            Real Estate Solutions
          </div>
          <div className={S.Logos}>
            <div className={S.Logo}>
              <a href="https://kinddevelopments.ca/" target={`_blank`}>
                <StaticImage
                  src="../../../images/logo_KiND.svg"
                  alt="Logo of the KiND"
                  placeholder="none"
                  style={{ maxHeight: `80px` }}
                  imgStyle={{ maxHeight: `80px` }}
                  objectFit="contain"
                />
              </a>
            </div>

            <div className={S.Logo}>
              <a href="https://epicres.com/" target={`_blank`}>
                <div className={S.MarketingBy}>Marketing & Sales By</div>
                <StaticImage
                  src="../../../images/logo_epic.svg"
                  alt="Logo of the EPIC"
                  placeholder="none"
                  style={{ maxHeight: `80px` }}
                  imgStyle={{ maxHeight: `80px` }}
                  objectFit="contain"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
