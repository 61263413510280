import React from "react";
import * as S from "./Header.module.sass";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

type Props = {
  className?: string;
  click: () => void;
};

const Header = ({ className, click }: Props) => {
  return (
    <header
      id={`header`}
      className={classNames(["header", className, S.Header])}
    >
      <div className={S.HeaderInner}>
        <div className={S.Tagline}>
          One and Two Bedroom Homes
          <br />
          starting at $399K
        </div>
        <div className={S.LogoContainer}>
          <StaticImage
            src="../../../images/lakeview-logo-white.svg"
            alt="Logo of the Lakeview"
            backgroundColor={`transparent`}
            objectFit="contain"
            style={{ height: "70px" }}
            imgStyle={{ height: "70px" }}
            placeholder="none"
          />
        </div>
        <div className={S.RegisterButton}>
          <div className={S.Button} onClick={click}>
            Register Now
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
