import React from "react";
import * as S from "./Hero.module.sass";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

// @ts-ignore
import DateVideo from "../../../images/video.mp4";
import { ControlBar, Player } from "video-react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

type Props = {
  click: () => void;
};

const Hero = ({ click }: Props) => {
  return (
    <section className={classNames(["section section-hero", S.Hero])}>
      <div className={S.VideoContainer}>
        <div className="hero-video">
          <Player
            className={S.Player}
            preload="auto"
            fluid
            loop
            muted
            autoPlay
            playsInline
          >
            <source src={DateVideo} />
            <ControlBar disableCompletely={true} autoHide={true} />
          </Player>
        </div>
      </div>
      <header className={S.Header}>
        <div className={S.HeaderInner}>
          <div className={S.Tagline}>
            One and Two Bedroom Homes
            <br />
            starting at $399K
          </div>
          <div className={S.LogoContainer}>
            <StaticImage
              src="../../../images/lakeview-logo-white.svg"
              alt="Logo of the Lakeview"
              backgroundColor={`transparent`}
              objectFit="contain"
              style={{ height: "100px" }}
              imgStyle={{ height: "100px" }}
              placeholder="none"
            />
          </div>
          <div className={S.RegisterButton}>
            <div className={S.Button} onClick={click}>
              Register Now
            </div>
          </div>
        </div>
      </header>
      <div className={S.MainContainer}>
        <main className={S.Main}>
          <h1>
            Where Vineyard
            <br />
            Meets Village
          </h1>
          <hr />
          <h4>
            Okanagan Homes Starting at $399K, nestled among the vineyards of
            West Kelowna
          </h4>
          <p>
            Deep within the rolling hills and lush vineyards of West Kelowna,
            Lakeview Village is undergoing a milestone revitalization. Once
            complete, this attractive, perfectly placed community will emerge as
            the most desirable neighbourhood in town.
          </p>
          <div className={S.Button} onClick={click}>
            Register Now
          </div>
        </main>
      </div>
    </section>
  );
};

export default Hero;
