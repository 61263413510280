import React, { useEffect } from "react";
import classNames from "classnames";
import * as S from "./SlideStyle.module.sass";
import { StaticImage } from "gatsby-plugin-image";
import gsap, { Power1 } from "gsap";

type Props = {
  className: string;
  ms: gsap.core.Tween | undefined;
};

const SlideStyle = ({ className, ms }: Props) => {
  useEffect(() => {
    gsap.set("#shadow-2", {
      // @ts-ignore
      width: document.querySelector("#box-2").offsetHeight * 0.6666,
      // @ts-ignore
      height: document.querySelector("#box-2").offsetHeight,
      right: `-5%`,
      yPercent: 25,
    });

    if (window.innerWidth < 992 || window.innerWidth < window.innerHeight) {
      /**
       * Slide Style
       * Leave animation
       */
      const styleLeaveTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".leave-style",
          start: "center 75%",
          end: "center 25%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      styleLeaveTimeline.fromTo(
        ".leave-style",
        {
          x: -150,
          y: -50,
          opacity: 0,
          rotate: 60,
          scale: 0.8,
        },
        {
          x: -50,
          y: 0,
          opacity: 0.2,
          rotate: 0,
        }
      );

      /**
       * Slide Style
       * Shadow 2
       */
      const styleShadowTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: "#shadow-2",
          start: "top 100%",
          end: "bottom 50%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      styleShadowTimeline.fromTo(
        "#shadow-2",
        { clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)` },
        {
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        }
      );

      /**
       * Slide 2
       * Image Over
       */
      const styleImageOverTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: ".slide-2-image-cover",
          start: "top 110%",
          end: "bottom 60%",
          toggleActions: "play none none none",
          scrub: true,
        },
      });
      styleImageOverTimeline.fromTo(
        ".slide-2-image-cover",
        {
          clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
        },
        {
          clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
        }
      );
    }
  }, []);

  useEffect(() => {
    if (ms !== undefined) {
      if (window.innerWidth >= 992 || window.innerWidth > window.innerHeight) {
        /**
         * Slide Style
         * Leave animation
         */
        const styleLeaveTimeline2 = gsap.timeline({
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: ".leave-style",
            start: "left 75%",
            end: "right 25%",
            toggleActions: "play none none none",
          },
        });
        styleLeaveTimeline2.from(".leave-style", {
          x: -100,
          y: -50,
          opacity: 0,
          duration: 1,
          rotate: 60,
          ease: Power1.easeOut,
        });

        /**
         * Slide Style
         * Shadow 2
         */
        const styleShadowTimeline2 = gsap.timeline({
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: "#shadow-2",
            start: "left 75%",
            end: "right 25%",
            toggleActions: "play none none none",
            scrub: true,
          },
        });
        styleShadowTimeline2.fromTo(
          "#shadow-2",
          { clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)` },
          {
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          }
        );

        /**
         * Slide 2
         * Image Over
         */
        const styleImageOverTimeline2 = gsap.timeline({
          scrollTrigger: {
            containerAnimation: ms,
            horizontal: true,
            trigger: ".slide-2-image-cover",
            start: "left 75%",
            end: "right 25%",
            toggleActions: "play none none none",
            scrub: true,
          },
        });
        styleImageOverTimeline2.fromTo(
          ".slide-2-image-cover",
          {
            clipPath: `polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)`,
          },
          {
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)`,
          }
        );
      }
    }
  }, [ms]);

  return (
    <div className={classNames([className, S.SlideStyle])}>
      <div className={S.Grid}>
        <div className={S.ImageContainer}>
          <div id={`box-2`} className={S.Box}>
            <div id={`shadow-2`} className={S.Shadow} />
            <div className={S.Image}>
              <StaticImage
                className={S.Img}
                src="../../../../images/image-kitchen-rendering.jpg"
                alt="Relax on the rooftop"
                objectFit="cover"
                placeholder="none"
                loading={`eager`}
                imgStyle={{ transform: `scale(1.125)` }}
              />
              <StaticImage
                className={classNames([`slide-2-image-cover`, S.ImgOver])}
                src="../../../../images/image-kitchen-rendering.jpg"
                alt="Relax on the rooftop"
                objectFit="cover"
                placeholder="none"
                loading={`eager`}
              />
            </div>
          </div>
        </div>
        <main className={S.Main}>
          <h1>
            <div className={classNames(["leave-style", S.Leave])} />
            Host with Style
          </h1>
          <p>High-end finishings and Italian kitchens hallmark every home.</p>
        </main>
      </div>
    </div>
  );
};

export default SlideStyle;
