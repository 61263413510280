import React, { useEffect, useState } from "react";
import * as S from "./Slideshow.module.sass";
import gsap from "gsap";
import classNames from "classnames";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SlideRooftop from "./SlideRooftop";
import SlideStyle from "./SlideStyle";
import SlideInspired from "./SlideInspired";

gsap.registerPlugin(ScrollTrigger);

const SlideshowIndex = () => {
  const [master, updateMaster] = useState<gsap.core.Tween | undefined>();
  useEffect(() => {
    /**
     * Section Two
     * Slideshows Panels
     */

    // const slides = gsap.utils.toArray("#slides-container .slide");
    const slides = gsap.utils.toArray([".slide-a", ".slide-b", ".slide-c"]);
    let tl;
    if (window.innerWidth >= 992 || window.innerWidth > window.innerHeight) {
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".slideshow",
          pin: true,
          scrub: true,
          markers: false,
          // @ts-ignore
          start: `top-=${document.querySelector("#header").offsetHeight} top`,
          // @ts-ignore
          end: () => `+=${document.querySelector(".slideshow").offsetWidth}`,
        },
      });

      tl.to(slides, {
        xPercent: -100 * (slides.length - 1),
        ease: "none",
      });
      // @ts-ignore
      updateMaster(tl);
    }
  }, []);

  return (
    <section className={classNames(["section", "slideshow", S.Slideshow])}>
      <div id={`slides-container`} className={S.SlidesContainer}>
        <SlideRooftop className="slide slide-a" ms={master} />
        <SlideStyle className="slide slide-b" ms={master} />
        <SlideInspired className="slide slide-c" ms={master} />
      </div>
    </section>
  );
};

export default SlideshowIndex;
