// extracted by mini-css-extract-plugin
export var Box = "Section1-module--Box--7ZmVI";
export var Button = "Section1-module--Button--51MsD";
export var Image = "Section1-module--Image--qPcvt";
export var ImageContainer = "Section1-module--ImageContainer--AckTC";
export var ImageOver = "Section1-module--ImageOver--aZNTJ";
export var Inner = "Section1-module--Inner--YW3JU";
export var Leave = "Section1-module--Leave--uI7m7";
export var Main = "Section1-module--Main--7zLhA";
export var Pattern = "Section1-module--Pattern--+2VQf";
export var Section1 = "Section1-module--Section1--F70Q3";
export var Shadow = "Section1-module--Shadow--ZcNeJ";