import React, { useEffect, useRef } from "react";
import * as S from "./Loading.module.sass";
import DateVideo from "../../images/loading.mp4";
import { BigPlayButton, ControlBar, Player } from "video-react";
import gsap, { Power2 } from "gsap";

type Props = {
  updateLoading: (value: boolean) => void;
};

export const hideScrollBar = () => {
  if (typeof window !== "undefined") {
    const body = document?.querySelector("body");
    // @ts-ignore
    body.style.overflow = "hidden";
  }
};

export const showScrollBar = () => {
  if (typeof window !== "undefined") {
    const body = document?.querySelector("body");
    // @ts-ignore
    body.style.overflow = "visible";
  }
};

const Loading = ({ updateLoading }: Props) => {
  const player = useRef(null);

  hideScrollBar();

  useEffect(() => {
    if (player !== null) {
      player.current?.subscribeToStateChange((state, prevState) => {
        if (state.ended) {
          gsap.to("#loading", {
            y: "-100%",
            duration: 0.8,
            delay: 1,
            ease: Power2.easeInOut,
            onComplete: () => {
              updateLoading(false);
              showScrollBar();
            },
          });
        }
      });
    }
  }, [player]);

  return (
    <div id={`loading`} className={S.Loading}>
      <Player
        className={S.Player}
        ref={player}
        preload="auto"
        height={`100%`}
        muted
        autoPlay
        playsInline
      >
        <source src={DateVideo} />
        <BigPlayButton position="center" />
        <ControlBar disableCompletely={true} autoHide={true} />
      </Player>
    </div>
  );
};

export default Loading;
