import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./AmenityMap.module.sass";
import classNames from "classnames";
import DATA, { I__Spot } from "./Data";
import _ from "lodash";

import {
  selectCategory,
  selectSpot,
  T__Category,
  updateCategory,
  updateSpot,
} from "../../../redux/dataSlice";
import Index from "./SVG";
import gsap from "gsap";

type Props = {
  className?: string;
};

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.4,
      easing: "linear",
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      easing: "linear",
    },
  },
};

const AmenityMap = ({ className }: Props) => {
  const dispatch = useDispatch();
  const category: T__Category = useSelector(selectCategory);
  const spot = useSelector(selectSpot);

  const LIST: Array<I__Spot> | undefined = _.filter(DATA, { category });

  // console.log("LIST =", LIST);

  const click = (category: T__Category) => {
    dispatch(updateCategory({ category }));
  };

  const buttonClasses = (c: T__Category) => {
    const classes = [
      S.Button,
      {
        [`${S.Active}`]: c === category,
        [`${S.Grocery}`]: c === "Grocery Stores",
        [`${S.Pubs}`]: c === "Pubs & Restaurants",
        [`${S.Wineries}`]: c === "Wineries",
        [`${S.Parks}`]: c === "Parks & Recreation",
      },
    ];
    return classNames(classes);
  };

  const clickList = (spot: number) => {
    dispatch(updateSpot({ spot }));
  };

  const itemClass = (num: number) => {
    const classes = [{ [`${S.Highlight}`]: spot === num }];
    return classNames(classes);
  };

  useEffect(() => {
    /**
     * Amenity Map
     * Leave
     */
    const amenityLeaveTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: "#amenity-map",
        start: "top center+=100px",
        end: "bottom bottom",
        toggleActions: "play none none none",
        scrub: true,
      },
    });
    amenityLeaveTimeLine.from("#amenity-map-leave", {
      x: 200,
      opacity: 0,
      duration: 1,
      rotate: -60,
    });

    /**
     * Amenity Map
     * Shadow
     */
    const amenityShadowTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: "#amenity-map",
        start: "top center+=100px",
        end: "bottom bottom",
        toggleActions: "play none none none",
        scrub: true,
      },
    });
    amenityShadowTimeLine.from("#amenity-map-shadow", {
      yPercent: 50,
      opacity: 0,
      duration: 1,
    });

    /**
     * Amenity Map
     * Over
     */
    const amenityOverTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: "#amenity-map",
        start: "top center+=100px",
        end: "bottom bottom",
        toggleActions: "play none none none",
        scrub: true,
      },
    });
    amenityOverTimeLine.to("#amenity-map-over", {
      width: 0,
      duration: 1,
    });
  }, []);

  return (
    <div id={`amenity-map`} className={classNames([className, S.AmenityMap])}>
      <div className={S.Inner}>
        <div className={S.Content}>
          <div id="amenity-map-leave" className={S.Leave}></div>
          <h1 className={S.Heading}>
            Live Where Vineyard
            <br />
            Meets Village
          </h1>
          <p>
            Everything you need is right outside your door! Pick up groceries at
            Nester’s Market, pop into Lakesider Brewing for drinks or make your
            way to one of many wineries for a tasting or dinner. Nearby are
            wooded trails ideal for explorations with your pet, a bike-friendly
            route leading to a regional park, and a community centre complete
            with pickleball and tennis courts. The Lakeview Village lifestyle{" "}
            <span className={S.NoWrap}>is truly unmatched.</span>
          </p>
        </div>

        <div className={S.InteractiveContainer}>
          <div id={`amenity-map-shadow`} className={S.AmenityMapShadow} />
          <div className={S.CategoryButtons}>
            <div
              className={buttonClasses("Grocery Stores")}
              onClick={() => click("Grocery Stores")}
            >
              <span>Grocery Stores</span>
            </div>
            <div
              className={buttonClasses("Pubs & Restaurants")}
              onClick={() => click("Pubs & Restaurants")}
            >
              <span>Pubs & Restaurants</span>
            </div>
            <div
              className={buttonClasses("Wineries")}
              onClick={() => click("Wineries")}
            >
              <span>Wineries</span>
            </div>
            <div
              className={buttonClasses("Parks & Recreation")}
              onClick={() => click("Parks & Recreation")}
            >
              <span>Parks & Recreation</span>
            </div>
          </div>
          <div className={S.Container}>
            <div className={S.MapContainer}>
              <div className={S.MapBackground} />
              <Index />
            </div>
            <div className={S.List}>
              <div className={S.Padding}>
                <h2>{category}</h2>
                <ol start={LIST[0].id}>
                  <AnimatePresence>
                    {LIST?.map((item, index) => (
                      <motion.li
                        className={itemClass(item.id)}
                        key={index}
                        onClick={() => clickList(item.id)}
                        exit="exit"
                        initial="initial"
                        animate="animate"
                        variants={animation}
                      >
                        <span>{item.title}</span>
                      </motion.li>
                    ))}
                  </AnimatePresence>
                </ol>
              </div>
            </div>
          </div>
          <div id={`amenity-map-over`} className={S.AmenityMapOver} />
        </div>
      </div>
    </div>
  );
};

export default AmenityMap;
